<template>
    <v-card class="card pa-3 mb-3 flat-card">
      <v-row class="">
        <v-col cols="6" class="py-1">
          <span class="medium-font">{{$t('product.totalPrice')}} : </span>
        </v-col>
        <v-col cols="6" class="py-1">
          <span class="medium-font numbers-font" v-text="total_price.gross_price_format"></span>
        </v-col>
      </v-row>
      <v-row class=" pb-2">
        <v-col cols="6" class="py-1">
          <span class="medium-font">{{$t('bill.shippingCost')}} : </span>
        </v-col>
        <v-col cols="6" class="py-1">
          <span class="medium-font numbers-font" v-text="total_price.post_price_format"></span>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="6">
          <span class="font primaryText--text">{{$t('bill.payable')}}</span>
        </v-col>
        <v-col cols="6">
          <span class="font numbers-font primaryText--text" v-text="payment"></span>
        </v-col>
      </v-row>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { changeTypePrice_factor } from '@/models/changeType'
export default {
  data: () => ({
    total: '',
    after_purse_payment: ''
  }),
  computed: {
    ...mapState({
      total_price: state => state.basket.payment_defult,
    }),
    loading () {
      let with_post = this.total_price.gross_price
      if (parseInt(this.total_price.gross_price) < parseInt(this.total_price.post_thershold)) {
        with_post = parseInt(this.total_price.gross_price) + parseInt(this.total_price.post_price_constant)
      }
      if (!with_post) {
        return true
      } else {
        return false
      }
    },
    payment () {
      let with_post = this.total_price.gross_price
      if (parseInt(this.total_price.gross_price) < parseInt(this.total_price.post_thershold)) {
        with_post = parseInt(this.total_price.gross_price) + parseInt(this.total_price.post_price_constant)
      }
      const total= changeTypePrice_factor({
          price: with_post,
          Tax_price: this.total_price.tax,
      })
      return total
    }
  }
}
</script>