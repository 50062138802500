<template>
<div class="product-slider-container">
  <div class="mt-4 mb-4 mx-3">
    <span class="base-font primaryText--text font-weight-bold" v-text="block.block_template.title"></span>
  </div>
  <v-card class="card flat-card mt-5 pa-3">
    <splide :options= "options" :slides = 'slides'>
      <splide-slide v-for="slide  in slides" :key="slide.id">
        <product :product = 'slide' />
      </splide-slide>
    </splide>
    <splide v-if="slides.length < 1" :options="option" :slides = 'slides'>
      <splide-slide>
        <v-skeleton-loader
          v-bind="attrs"
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          v-bind="attrs"
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          v-bind="attrs"
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          v-bind="attrs"
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
    </splide>
    </v-card>

</div>
</template>
<style lang="scss" scoped>
.v-menu__content.theme--light.menuable__content__active{
  z-index: 0 !important;
}
</style>
<script>
import product from '../cards/product/sliderCard'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { mapState } from 'vuex'
export default {
  components: {
    product,
    Splide,
    SplideSlide,
  },
  props: ['options', 'block'],
  computed: {
    ...mapState({
      productBlock: state => state.home.blockProduct
    }),
    slides () {
      let products = []
      for (const product of this.productBlock) {
        if (product.parentID == this.$props.block.id) {
          products = product.products
          return products
        }
      }
      return products
    }
  },
  data: () => ({
    blockID: '',
    items: [],
    benched: 0,
    options: {
      rewind : true,
      trimSpace: true,
      width: '100%',
      perPage: 4,
      arrows: true,
      drag: true,
      pagination: false,
      waitForTransition: false,
      perMove: 1,
      gap: '0.5rem',
      start: 0,
      focus: 'center',
      direction: 'rtl'
    },
  }),
}
</script>
<style lang="scss" scoped>
.grid{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 95%;
  align-items: center;
}
</style>