
const state = {
    user: null,
  }
  
  const mutations = {
      setUser: (state,paylod) => {
          state.user = paylod
      },
      remove_image: (state, paylod) => {
        state.user.image = paylod
      }
  }
  
  const actions = {
      setUser: ({commit}, paylod) => {
          commit('setUser',paylod)
      },
      remove_image: ({commit}, paylod) => {
        commit('remove_image', paylod)
      }
  
  }
  
  export default { state, mutations, actions }