<template>
  <div>
    <navbar/>
    <div class="fixed-alert">
      <alert/>
    </div>
    <v-container fluid>    
      <v-row class="mt-1 mx-1">
        <div @click="toBasket()" class="d-flex align-center">
          <v-icon class="info--text">{{$t('categories.arrowR')}}</v-icon>
          <span class="info--text mr-1 font click">{{$t('basket.backToCart')}}</span>
        </div>
      </v-row>
      <v-row class="mx-2 pt-2 mb-4">
        <v-col cols="12" md="8" class="pa-0">
          <receiver/>
        </v-col>      
        <v-col cols="12" md="4" class="py-0 px-3">
          <cost :text="$t('basket.setDeliveryTime')"/>
        </v-col>
      </v-row>
    </v-container>
    <cFooter/>
  </div>
</template>
<script>

import receiver from '@/components/cards/receiverCards/addressCard'
import cost from '@/components/cards/basket/costBasketCard'
import navbar from '@/components/navbar/basketNav'
import { basketResiver ,viewbasket_products } from './models/basket'
import cFooter from '@/components/footer/footer'
import alert from '@/components/alerts/alert'
export default {
  components: {
    navbar,
    receiver,
    cost,
    cFooter,
    alert
  },
  created () {
    basketResiver()
    viewbasket_products()
  },
  methods: {
    toBasket(){
      this.$router.push('/basket')
    }
  }
}
</script>
<style>
.click{
cursor: pointer;
}
</style>
