<template>
  <div>
    <v-alert border="left" v-if="open" :color="color" :type="type" dark>
      {{textMessage}}
    </v-alert>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {alertMessage} from '@/assets/messageAlert'
import { setLang } from '@/models/changeType'
export default {
  computed: {
      ...mapState({
        typeMessage: state => state.alert.typeMessage,
        open: state => state.alert.show,
        type: state => state.alert.type,
        color: state => state.alert.color
      }),
      textMessage () {
        const textMessage = alertMessage[this.type][this.typeMessage]
        return textMessage
      }
    }
}
</script>