<template>
<v-form @submit.prevent="change_password" ref="changePass">
  <v-card class="card flat-card pa-3">
    <v-row class="my-2 mx-2">
      <span class="font primaryText--text">{{$t('auth.changePass')}}</span>
    </v-row>
    <v-row class="mx-2">
      <v-text-field
        v-model="passwords.old_password"
        maxlength="21"
        :label="$t('auth.oldPass')" 
        :type="showPassword_old ? 'text' : 'password'"
        prepend-icon="mdi-lock-outline"
        :rules="[ rules.required, rules.count, rules.count_password ]"
        :append-icon="showPassword_old ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword_old = !showPassword_old"/>
    </v-row>
    <v-row class="mx-2">
      <v-text-field
        v-model="passwords.new_password"
        maxlength="21"
        :label="$t('auth.newPass')"
        :type="showPassword_new ? 'text' : 'password'"
        prepend-icon="mdi-lock-outline"
        :rules="[ rules.required, rules.count, rules.count_password ]"
        :append-icon="showPassword_new ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword_new = !showPassword_new"/>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="check" :disabled="submit_btn" class="primary button" type="submit">
        {{$t('auth.save')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-form>
</template>
<script>
import { change_password } from '@/views/adminPanel/models/user'
import { alertMessage } from '@/assets/messageAlert'
import { mapState } from 'vuex'
export default {
  data: () => ({
    showPassword_old:false,
    showPassword_new:false,
    passwords: {},
    rules: {
      required: null,
      count: value => value.length <= 21 || alertMessage.error['MaxCharacters'],
      count_password: value => value.length >= 6 || alertMessage.error['min_password']
    }
  }),
  computed: {
    ...mapState({
      submit_btn: state => state.loadingSubmits
    })
  },
  methods: {
    check () {
      this.rules = {
        required: value => !!value || alertMessage.error['Required']
      }
    },
    change_password () {
      const validate = this.$refs.changePass.validate()
      if ( validate == true) {
      change_password(this.passwords)
      }
    }
  }
}
</script>