<template>
  <div>
    <slider class="mt-7"/>
  </div>
</template>
<script>
import { galleries, getblock, blockList } from './shop/models/home'
import { mapState} from 'vuex'
import { viewbasket_products } from './shop/models/basket'
import slider from '@/components/sliders/test'
import store from '@/store'
export default {
  components: {
    slider
  },
  mounted () {
    let config = JSON.parse(localStorage.getItem('config'))
    localStorage.removeItem('filter_search')
    store.dispatch('productDetail')
    const basket = localStorage.getItem('basket')
      if (basket) {
        store.dispatch('basket_local', JSON.parse(basket))
      } else {
        viewbasket_products()
      }
    viewbasket_products()
    if (this.blockProduct.length < 1) {
      getblock()
      galleries(config.mobile_slider_gallery,'banner')
      galleries(config.brands_gallery,'brands')
    }
  },
  computed: {
    ...mapState({
      loading_page: state => state.loading_page,
      blockProduct: state => state.home.blockProduct,
      blocks: function (state) {
        const blocks = state.home.blocks
        if (this.blockProduct.length < 1) {
          for (const block of blocks) {
            blockList(block.id)
        }
        }
        return blocks
      },
    })
  },
  data: () => ({
    drawer: false,
  }),
  methods:{
    open_cat (open) {
      this.drawer = open
    }
  }
}
</script>