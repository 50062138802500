<template>
<v-form ref="set_address" @submit.prevent="saveAddress()">
  <v-card class="pa-3 card">
    <v-row class="my-2 mx-2">
      <span class="primaryText--text font">{{$t('basket.addNew')}}</span>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
        v-model="address.title"
        :label="$t('auth.title')"
        :rules="[rules.count, rules.required]"
        maxlength="21"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          disabled
          v-model="Province"
          :label="$t('auth.state')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="city"
          disabled
          :label="$t('auth.city')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          maxlength="201"
          dense
          rows="1"
          :rules="[ rules.required, rules.address ]"
          v-model="address.address"
          :label="$t('auth.address')"
          counter="200"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          :label="$t('auth.zipCode')"
          v-model="address.postal_code"
          maxlength="12"
          :rules="[ rules.post_code, rules.type_number ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="$t('auth.receiver')"
          v-model="address.receiver"
          maxlength="21"
          :rules="[ rules.count, rules.required ]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="address.mobile"
          maxlength="11"
          :label="$t('auth.mobile')"
          :rules="[ rules.required, rules.mobile, rules.type_number,rules.mobile_count ]"
          type="tell"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="address.phone"
          :label="$t('auth.phone')"
          maxlength="10"
          :rules="[ rules.phone, rules.type_number ]"
          type="tell"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="back" class="button small-button" text>{{$t('auth.discard')}}</v-btn>
        <v-btn @click="check" :disabled="submit_btn" type="submit" class="button primary small-button">ثبت آدرس</v-btn>
    </v-card-actions>
  </v-card>
  </v-form>
</template>
<script>
import {alertMessage} from '@/assets/messageAlert'
import { setAddress } from '@/views/adminPanel/models/address'
import { toEnglishDigits } from '@/models/changeType'
import { mapState } from 'vuex'
  export default {
    data: () => ({
      array: [],
      loading: false,
      address: {
        title: '',
        address: '',
        postal_code: '',
        mobile: '',
        phone: ''
      },
      Province: 'خراسان رضوی',
      city: 'مشهد',
      rules: {
        required: null,
        count: value => value.length <= 20 || alertMessage['MaxCharacters'],
        mobile_count: value => value.length == 11 || alertMessage['mobile_count'],
        phone: value => {
          if (value) {
            const mobile = toEnglishDigits(value)
            return mobile.slice(0,2) == '05' || alertMessage['typePhone']
          }},
        mobile: value => {
          const mobile = toEnglishDigits(value)
          return mobile.slice(0,2) == '09' || alertMessage['typePhone']},
        address: value => value.length <= 200 || alertMessage['address'],
        post_code: value => value.length <= 10 || alertMessage['post_code'],
        type_number: value => {
          if (value) {
            const mobile = toEnglishDigits(value)
            const pattern = /^[0-9]+$/
            return pattern.test(mobile) || alertMessage['type_number']
          }
        },
      }
    }),
    computed: {
      ...mapState({
        submit_btn: state => state.loadingSubmit,
      })
    },
    methods: {
      type_address (item) {
        this.type = item
      },
      check () {
        this.rules = {
          required: value => !!value || alertMessage.error['Required']
        }
      },
      saveAddress () {
        const validate = this.$refs.set_address.validate()
        if (validate == true) {
          const address = { province: 18, province_name: 'خراسان رضوي', city: 182,
          city_name: 'مشهد', phone: toEnglishDigits(this.address.phone),
          title: this.address.title, type: 1,
          mobile: toEnglishDigits(this.address.mobile), receiver: this.address.receiver,
          address: this.address.address, postal_code: toEnglishDigits(this.address.postal_code),
          latitude: 0, longitude: 0 }
          setAddress(address)
          this.$emit('back', false)
        }
      },
      back () {
        this.$emit('back', false)
      }
    },
  }
</script>