<template>
  <div>
    <v-row class="pa-2 my-1 d-flex align-center">
      <v-icon @click="back" class="click primaryText--text mx-2" size="18">{{$t('categories.arrowR')}}</v-icon>
      <span @click="back" class="click medium-font primaryText--text">{{$t('categories.back')}}</span>
    </v-row>
    <v-divider class="mb-2"></v-divider>
    <span class="mx-2 font font-weight-black" v-text="title"></span>
    <v-list dense class="white">
      <div v-for="(category, index) in categories_list" :key="category.id">
      <v-list-item v-if="index < 8 && !show" link>
        <v-list-item-content @click="search(category)" class="blocksFont--text">
          <v-list-item-title v-text="category.title"></v-list-item-title> 
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="show" link>
        <v-list-item-content @click="search(category)" class="blocksFont--text">
          <v-list-item-title v-text="category.title"></v-list-item-title> 
        </v-list-item-content>
      </v-list-item>
      </div>
    </v-list>
    <v-btn class="primaryText--text mx-8" text @click="show = !show" v-if="!show && categories_list.length > 8">
    <v-icon size="18" class="primaryText--text">mdi-plus</v-icon>
    {{$t('search.seeAll')}}
    </v-btn>
    <v-btn class="primaryText--text mx-8 button" text @click="show = !show" v-if="show">
    <v-icon size="18" class="primaryText--text">mdi-minus</v-icon>
    <span class="medium-font primaryText--text">{{$t('search.seeLess')}}</span>
    </v-btn>
 </div>
</template>
<style lang="scss" scoped>
// .overlay{
//   position: fixed;
//   top: 107px;
//   left: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #00000094;
//   width: 100%;
//   height: 100%;
//   z-index: 999;
//   transition: opacity .2s ease;
// }

// .drawer-container{
//   height: 100%;
//   width: 330px;
//   overflow-x: hidden;
//   overflow-y: auto;
//   position: fixed;
//   top: 107px;
//   bottom: 0;
//   right: 0;
//   opacity: 1;
//   z-index: 999;
// }

.v-btn{
  letter-spacing: 0;
}
</style>
<script>
import { search_product_tag } from '@/views/shop/models/search'
import { mapState } from 'vuex'
export default {
  props: ['openDrawer', 'title'],
  data: () => ({
    show: false
  }),
    computed: {
      ...mapState({
          categories_list: state => state.categories.show_category_list
      })
    },
    methods: {
      back () {
        this.$emit('close', false)
      },
      search (category) {
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = 1
          let tages = category.tag[0]
          if (category.tag.length > 1) {
            for (const key of category.tag) {
              tages = key + ',' + tages
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
          setTimeout(() => {
            search_product_tag(filters)
          }, 500)
        } else {
          filters = {}
          filters.page = 1
          let tages = category.tag[0]
          if (category.tag.length > 1) {
            for (const key of category.tag) {
              tages = tages + ',' + key
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
          setTimeout(() => {
          search_product_tag(filters)
          }, 500)
      }
    }
    } 
}
</script>