import axios from "axios"
import Store from '@/store/index'
import router from "@/router"
type productsBasket = {
    count: string;
    price: string | number;
    Is_Bulk: string;
    Org_ID: string;
    id: string;
    image: string;
    max_request: string;
    onhand: string;
    title: string;
    unit_title: string;
}

const token = localStorage.getItem('access_token')

export function addTObasket (orders: any) {
  if (!token) {
    router.push('/register')
    return
  }
  Store.dispatch('addBasket_status', false)
  axios.post('/api/Store/Basket/addToBasket',orders.product,{
    headers: {
    'Authorization': `Bearer ${token}`
    }
}).then(Response => {
  if (Response.data.Result.error) {
    Store.dispatch("alertSuccess",{
      show: true,
      type: 'error',
      typeMessage:'error_add_basket',
      color:"error"
    })
    setTimeout(() => {
      Store.dispatch("alertSuccess",{
        show: false,
        type: 'success',
        typeMessage:'error_add_basket',
        color:"success"
      })
    }, 3000);
    Store.dispatch('addBasket_status', true)
    Store.dispatch('addBasket_error', true)
    setTimeout(() => {
      Store.dispatch('addBasket_error', false)
    }, 1000);
    return
  }
  Store.dispatch('addBasket', {price: orders.price, type: orders.type, product: orders.product})
  Store.dispatch('addBasket_status', true)
  Store.dispatch('addcountBasket', {product: orders.product_to_basket , count: orders.product.count})
  Store.dispatch('addBasket_error', false)
}).catch (() => {
  Store.dispatch("alertSuccess",{
    show: true,
    type: 'error',
    typeMessage:'network',
    color:"error"
  })
  setTimeout(() => {
    Store.dispatch("alertSuccess",{
      show: false,
      type: 'success',
      typeMessage:'network',
      color:"success"
    })
  }, 3000);
})
}
export function removeTObasket (orders: {}) {
    axios.post('/api/Store/Basket/removeFromBasket',orders,{
      headers: {
      'Authorization': `Bearer ${token}`
      }
  }).then(Response => {
    if (Response.data.Result.error) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'network',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'network',
          color:"success"
        })
      }, 3000);
      return
    }
    Store.dispatch('removeBasket', orders)
    Store.dispatch('removecountBasket', orders)
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function viewbasket () {
    axios.get('/api/Store/Basket/show',{
      headers: {
      'Authorization': `Bearer ${token}`
      }
  }).then(Response => {
    console.log(Response.data)
    if (Response.data.Result.products.length == 0) {
      router.push('/basket')
      return
    }
      Store.dispatch('payment', Response.data.Result.basket)
      console.log(Response.data.Result.basket.purses)
      Store.dispatch('purse_basket', Response.data.Result.basket.purses)
  }).catch ((e) => {
    const error = console.error(e);
  })
}
export function viewbasket_products () {
  Store.dispatch('load_page', true)
  axios.get('/api/Store/Basket/showProducts',{
    headers: {
    'Authorization': `Bearer ${token}`,
    }
}).then(Response => {
  Store.dispatch('load_page', false)
  if (Response.data.Result.products) {
    Store.dispatch('basket', Response.data.Result)
  }
}).catch ((e) => {
  const error = console.error(e);
})
}

export function view_times_deliveries () {
  Store.dispatch('load_page', true)
  axios.get('/api/Store/Basket/show',{
    headers: {
    'Authorization': `Bearer ${token}`
    }
}).then(Response => {
  if (Response.data.error == 'basket_change_error') {
    router.push('/basket')
  }
  Store.dispatch('load_page', false)
  if (Response.data.Result.products.length == 0) {
    router.push('/basket')
  }
  Store.dispatch('deliveries', Response.data.Result.basket.cargoes[0])
}).catch ((e) => {
  // const error = console.error(e);
})
}

export function basketResiver() {
  Store.dispatch('load_page', true)
    axios.get('/api/Store/Basket/getAddresses',{
      headers: {
      'Authorization': `Bearer${token}`
      }
     }).then(Response => {
      Store.dispatch('load_page', false)
      if (Response.data.Result.error) {
        Store.dispatch("alertSuccess",{
          show: true,
          type: 'error',
          typeMessage:'network',
          color:"error"
        })
        setTimeout(() => {
          Store.dispatch("alertSuccess",{
            show: false,
            type: 'success',
            typeMessage:'network',
            color:"success"
          })
        }, 3000);
        return
      }
       Store.dispatch('setAddressUser', Response.data.Result)
     }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
  })
}

export function deletedAddressReciver (addressID: '') {
  Store.dispatch('load_page', true)
  axios.post('/api/Store/Basket/deleteAddress', 
  {
      address_id: addressID
  },{
      headers: {
      'Authorization': `Bearer ${token}`
      }
  }).then(Response => {
    Store.dispatch('load_page', false)
    if (Response.data.Result.error) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'network',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'network',
          color:"success"
        })
      }, 3000);
      return
    }
      if (Response.data.Result == true) {
        Store.dispatch('removeAddressUser', addressID)
      }
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function submitReciver (addressID: '') {
  Store.dispatch('load_page', true)
  axios.get(`/api/Store/Basket/chooseAddress/${addressID}`,{
      headers: {
      'Authorization': `Bearer ${token}`
      }
  }).then(Response => {
    Store.dispatch('load_page', false)
    if (Response.data.Result.error) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'network',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'network',
          color:"success"
        })
      }, 3000);
      return
    }
      router.push('/deliveryTime')
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function setReceiver(address: {}) {
  Store.dispatch('load_page', true)
  axios.post('/api/Store/Basket/setAddress', address,{
    headers: {
    'Authorization': `Bearer ${token}`
    }
}).then(Response => {
  Store.dispatch('load_page', false)
  if (Response.data.Result.error) {
    Store.dispatch("alertSuccess",{
      show: true,
      type: 'error',
      typeMessage:'network',
      color:"error"
    })
    setTimeout(() => {
      Store.dispatch("alertSuccess",{
        show: false,
        type: 'success',
        typeMessage:'network',
        color:"success"
      })
    }, 3000);
    return
   }
    if (Response.data.Result.success == 1) {
      submitReciver(Response.data.Result.address[0].id)
    }
}).catch (() => {
  // Store.dispatch('alertError', {show: true, type: 'network'})
 })
}

export function viewProducts (productID: '') {
  Store.dispatch('load_page', true)
  axios.post('api/Store/Products/view',{
    product_id: productID
  }).then(Response => {
    Store.dispatch('load_page', false)
    if (Response.data.Result.error) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'network',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'network',
          color:"success"
        })
      }, 3000);
      return
    }
    Store.dispatch('productDetail', Response.data.Result)
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function setTimeSend (timeSend: any) {
  Store.dispatch('load_page', true)
  axios.post('api/Store/Basket/chooseDelivery',timeSend,{
      headers: {
      'Authorization': `Bearer ${token}`
      }
  }).then(Response => {
    Store.dispatch('load_page', false)
    if (Response.data.Result.error) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'network',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'network',
          color:"success"
        })
      }, 3000);
      return
    }
    if (Response.data.Result == true) {
      router.push('/payment')
    }
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function similarProduct (productID: string) {
  axios.post('/api/Store/Products/similar',{
    product_id: productID
  },{
    headers: {
    'Authorization': `Bearer ${token}`
    }
}).then(Response => {
  if (Response.data.Result.error) {
    Store.dispatch("alertSuccess",{
      show: true,
      type: 'error',
      typeMessage:'network',
      color:"error"
    })
    setTimeout(() => {
      Store.dispatch("alertSuccess",{
        show: false,
        type: 'success',
        typeMessage:'network',
        color:"success"
      })
    }, 3000);
    return
  }
  Store.dispatch('similarProducts', Response.data.Result)
}).catch (() => {
  // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function coupon (coupone: string) {
  Store.dispatch('load_page', true)
  axios.post('api/Store/Basket/getCoupon',{
    code: coupone
  },{
    headers: {
    'Authorization': `Bearer ${token}`
    }
}).then(Response => {
  Store.dispatch('load_page', false)
  if (Response.data.error == 'invalid_coupon') {
    Store.dispatch('invalid_coupon', Response.data.error_description)
    return
  }
  Store.dispatch('cal_coupon', Response.data.Result)
}).catch (() => {
  // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function submit_pay(payment: {}) {
  Store.dispatch('load_page', true)
  axios.post('/api/Store/Basket/submit', payment,{
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(Response => {
    Store.dispatch('load_page', false)
    if (Response.data.error == 'Basket_Error') {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'error_submit_basket',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'error_submit_basket',
          color:"success"
        })
        router.push('/basket')
      }, 3000);
      return
    }
    if (Response.data.Result.url) {
      location.replace(Response.data.Result.url + '?token=' + localStorage.getItem('access_token'))
    } else {
      router.push('/panel/myOrders')
    }
  })
}
export function remove_all_basket (orders) {
  axios.post('/api/Store/Basket/removeFromBasket',orders,{
    headers: {
    'Authorization': `Bearer ${token}`
    }
}).then(Response => {
  if (Response.data.Result.error) {
    Store.dispatch("alertSuccess",{
      show: true,
      type: 'error',
      typeMessage:'remove_all_basket',
      color:"error"
    })
    setTimeout(() => {
      Store.dispatch("alertSuccess",{
        show: false,
        type: 'success',
        typeMessage:'remove_all_basket',
        color:"success"
      })
      location.reload()
    }, 3000);
    return
  }
}).catch (() => {
  // Store.dispatch('alertError', {show: true, type: 'network'})
})
}