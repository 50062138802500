<template>
  <div class="header">
    <div class="primary full-width py-1 px-2 d-flex align-center">
      <v-row align="center">
        <v-col cols="8">
          <v-btn width="20" class="px-0" text @click="open_cat()"><v-icon class="white--text" size="30">mdi-menu</v-icon></v-btn>
          <span class="white--text mr-2">{{$t('categories.categories')}}</span>
        </v-col>
        <v-col cols="3" class="d-flex justify-end">
          <v-btn @click="login()" v-if="!user" class="button" color="white" outlined>
            <span class="medium-font white--text">{{$t('auth.login')}}</span>
            <span class="mx-1 white--text">/</span>
            <span class="medium-font white--text">{{$t('auth.register')}}</span>
          </v-btn>
          <div v-if="user" class="d-flex align-center profile-drawer-container">
            <div @click="profile=!profile" style="cursor:pointer">
              <v-icon class="white--text mx-1" size="18">mdi-chevron-down</v-icon>
              <span class="medium-font white--text" v-text="user.nick_name"></span>
            </div>
            <v-avatar size="30" class="mx-1">
              <v-img :src="user.image"></v-img>
            </v-avatar>
          </div>
        </v-col>
        <v-col cols="1">
          <div v-if="!page_basket" class="ml-3">
            <div class="cart-content-container">
              <v-icon class="white--text ml-1" size="18" @click="content = !content">mdi-chevron-down</v-icon>
              <v-badge v-if="countBasket != 0" color="error" :content="countBasket.length" offset-x="8" offset-y="-6" style="z-index: 1"></v-badge>
                <v-icon size="30" class="white--text" @click="content = !content">mdi-cart</v-icon>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="transparent-overlay" @click.self="content=!content" v-if="content">
      <basketContent class="basket-content" v-if="content"/>
    </div>
    <div class="transparent-overlay" @click.self="profile=!profile" v-if="profile">
      <profile v-if="profile"/>
    </div>
  </div>
</template>
<script>

import basketContent from '@/components/drawers/basketDrawer'
import profile from '../drawers/profileDrawer'
import { mapState} from 'vuex'
import { getProfile } from '@/views/adminPanel/models/user'
export default {
  components: {
    basketContent,
    profile
  },
  data: () => ({
    content: false,
    profile: false,
    open_cat_: false,
    page_basket: false
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      countBasket: state => state.basket.basketCount
    })
  },
  created () {
    const login = localStorage.getItem('access_token')
    if (login) {
      getProfile()
    }
    const route = this.$router.history.current.name
    if (route == 'basket') {
      this.page_basket = true
    }
  },
  methods:{
    open_cat () {
      this.open_cat_ = !this.open_cat_
      this.$emit('open_cat', this.open_cat_)
    },
    login() {
      this.$router.push('/login')
    }
  }
}
</script>