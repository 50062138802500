<template>
  <div class="d-flex justify-center align-center mx-auto py-1">
    <div>
      <a :disabled='loading' type="button">
        <v-icon @click="addedTobasket" size="18" class="primaryText--text accent pa-1 rounded-circle">mdi-plus</v-icon>
      </a>
    </div>
    <div v-if="!loading" class="numbersBg number-container mx-2" dir="ltr">
      <input type="text" class="number-input numbers-font" v-model="count" :placeholder="count">
    </div>
    <div v-if="loading" class="numbersBg number-container mx-1">
      <span class="number-input" style="position: relative">
        <small-loading/>
      </span>
    </div>
    <div>
      <a :disabled='loading' type="button">
        <v-icon size="18" class="primaryText--text accent pa-1 rouded-button" v-if="this.count != product.min_order"  @click="removebasket" >mdi-minus</v-icon>
        <v-icon size="18" class="primaryText--text accent pa-1 rouded-button" v-if="this.count == product.min_order"  @click="removeProduct">mdi-trash-can-outline</v-icon>
      </a>
      </div>
  </div>
</template>
<script>
import SmallLoading from '../loadings/smallLoading.vue'
import { addTObasket, removeTObasket } from '../../views/shop/models/basket'
import Store from '@/store/index'
import { mapState } from 'vuex'
import { toEnglishDigits } from '@/models/changeType'
export default {
  components: {
    SmallLoading
  },
    props: ['product'],
  data: () => ({
    addbasket: '',
    total: 0,
    count: 0,
    loading: false
  }),
  computed: {
    ...mapState({
      addBasket_status: state => state.basket.addBasket_status,
      addBasket_error: state => state.basket.addBasket_error,
      basket: state => state.basket.basket,
    })
  },
  watch: {
    addBasket_status: {
      handler: function () {
        if (this.addBasket_status == true && this.addBasket_error == false) {
          this.loading = false
          this.count = this.addbasket
          this.$emit('count', this.count)
        } else if (this.addBasket_error == true) {
          this.loading = false
        }
      }
    }
  },
  mounted () {
    let product = this.$props.product
    if (this.basket.length == 0) {
      product.count = 0
    } else{
      for (const item of this.basket) {
      if (item.id == product.id) {
        product = item
      }
    }
    }
    if (product.count != 0) {
      this.addbasket = parseInt(product.count)
    this.count = parseInt(product.count)
    this.total =  (parseInt(product.price)) * product.count
    this.$emit('count', this.count)
    } else {
      this.addbasket = parseInt(this.$props.product.min_order)
      this.count = parseInt(this.$props.product.min_order)
      addTObasket({product: { product_id: this.$props.product.id ,count: this.$props.product.min_order, org_id: this.$props.product.org_id },
        type: '+',
        price: (this.addbasket * (parseInt(this.$props.product.price)) - this.total),
        product_to_basket: this.$props.product
      })
      this.total = this.addbasket * (parseInt(this.$props.product.price))
    }
  },
    methods: {
      checkInput () {
        const count = toEnglishDigits(this.count)
        setTimeout(() => {
          const reg = new RegExp('^[0-9]+$')
          if (!reg.test(count) && count) {
          this.loading = false
          Store.dispatch('alertError', {show: true, type: 'error_count'})
            setTimeout(() => {
              this.count = this.addbasket
              Store.dispatch('alertError', {show: false, type: 'error_count'})
            }, 500)
          return
        }
        this.addbasketinput(count)
        }, 2500)
      },
    addedTobasket () {
      this.loading = true
      Store.dispatch('alertError', {show: false, type: 'capacity'})
      const Max_Order = Math.min(this.$props.product.onhand,this.$props.product.max_order)
        if (this.addbasket < Max_Order) {
          this.addbasket = this.addbasket + parseInt(this.$props.product.lot_size)
            addTObasket({product: { product_id: this.$props.product.id ,count: this.addbasket, org_id: this.$props.product.org_id },
              type: '+',
              price: (this.addbasket * (parseInt(this.$props.product.price)) - this.total),
              product_to_basket: this.$props.product
            })
            this.total = this.addbasket * (parseInt(this.$props.product.price))
        } else {
          this.loading = false
          Store.dispatch("alertSuccess",{
      show: true,
        type: 'error',
        typeMessage:'capacity',
        color:"error"
    })
    setTimeout(() => {
      Store.dispatch("alertSuccess",{
        show: false,
        type: 'success',
        typeMessage:'network',
        color:"success"
      })
    }, 2500);
        }
    },
    removebasket () {
      this.loading = true
      if (this.addbasket > this.$props.product.min_order) {
          this.addbasket = this.addbasket - parseInt(this.$props.product.lot_size)
            addTObasket({product: { product_id: this.$props.product.id ,count: this.addbasket, org_id: this.$props.product.org_id },
              type: '-',
              price: (this.total - (this.addbasket * (parseInt(this.$props.product.price)))),
              product_to_basket: this.$props.product
            })
            this.total = this.addbasket * (parseInt(this.$props.product.price))
      }
    },
    addbasketinput (count) {
      this.loading = true
      const Max_Order = Math.min(this.$props.product.onhand,this.$props.product.max_order)
      if (parseInt(count) > parseInt(this.$props.product.min_order) && parseInt(count) < parseInt(Max_Order) ) {
        this.addbasket = count
        if (this.total > (parseInt(this.$props.product.price))) {
          addTObasket({product: { product_id: this.$props.product.id ,count: count, org_id: this.$props.product.org_id },
            type: '-',
            price: (this.total - this.addbasket * (parseInt(this.$props.product.price))),
            product_to_basket: this.$props.product
          })
           this.total = this.addbasket * (parseInt(this.$props.product.price))
        }
        if (this.total < (parseInt(this.$props.product.price))) {
          addTObasket({product: { product_id: this.$props.product.id ,count: count, org_id: this.$props.product.org_id },
          type: '+',
          price: (this.total - (parseInt(this.$props.product.price))),
          product_to_basket: this.$props.product
          })
        this.total = (count * (parseInt(this.$props.product.price)))
      }
      } else {
        if (count) {
          this.loading = false
        Store.dispatch('alertError', {show: true, type: 'error_count'})
          setTimeout(() => {
            this.count = this.addbasket
            Store.dispatch('alertError', {show: false, type: 'error_count'})
          }, 3000)
        }
      }
    },
    removeProduct () {
        removeTObasket({ product_id: this.$props.product.id, org_id: this.$props.product.org_id, price: this.total})
        this.$emit('show',false)
    }
  }
}
</script>

