<template>
    <div>
      <v-card v-for="product in products" :key="product.id" class="card flat-card no-border" >
      <v-row>
        <v-col cols="3" md="2" class="d-flex justify-center align-center">
          <div class="basket-card-img-container">
            <img :src="product.image" class="img-fluid">
          </div>
        </v-col>
        <v-col class="px-2">
          <v-row>
            <v-col cols="5" class="pb-0">
              <div class="title-container mt-0 ml-0">
                <span v-text="product.title"></span>
              </div>
            </v-col>
            <v-col v-if="product.Is_Bulk != 1 || !product.Is_Bulk" cols="5" class="d-flex flex-column justify-start align-center">
              <div class="mx-2">
                <span class="font numbers-font" v-text="product.price_formate"></span>
              </div>
              <div v-if="product.price_gross != product.price && product.price_gross">
                <span class="medium-font text-decoration-line-through error--text font-weight-bold" v-text="product.price_gross_format"></span>
                <span class="font font-weight-bold mx-2" style="color: transparent">تومان</span>
              </div>
            </v-col>
            <v-col v-if="product.Is_Bulk == 1" cols="5" class="d-flex flex-column justify-start align-center">
              <div class="mx-2">
                <span class="font numbers-font" v-text="product.price_format_pack"></span>
              </div>
              <div v-if="product.price_gross != product.price && product.price_gross">
                <span class="medium-font text-decoration-line-through error--text font-weight-bold" v-text="product.price_gross_format"></span>
                <span class="font font-weight-bold mx-2" style="color: transparent">تومان</span>
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <span class="font numbers-font mx-3" v-text="product.price_total"></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="4" class="d-flex align-center pb-1">
              <span v-if="product.Is_Bulk == 1" class="mx-2 font">{{$t('product.amount')}} : </span>
              <span v-if="product.Is_Bulk != 1 || !product.Is_Bulk" class="mx-2 font">{{$t('product.number')}} : </span>
              <quantityBtn v-if="product.Is_Bulk != 1 || !product.Is_Bulk" @count='count' :product= 'product' class="mx-2"/>
              <quatity v-if="product.Is_Bulk == 1" @showOpen ='showOpen' color="primary" height= '30' :product= 'product' title="تعداد"/> 
            </v-col>
            <v-spacer/>
            <v-col cols="1"  class="d-flex align-center justify-center pa-2">
              <v-icon size="19" v-if="product.count != product.min_order || product.Is_Bulk == 1" @click="remove_item(product)" class="primaryText--text cursor-pointer medium-font">mdi-trash-can-outline</v-icon>
            </v-col>
            <!-- <v-col cols="6" class="d-flex align-center justify-start pa-2">
            <span class="primaryText--text medium-font">مقایسه با کالای مشابه</span>
            </v-col> -->
            <!-- <v-col class="d-flex justify-end align-center">
              <span class="medium-font mx-2">{{$t('product.totalPrice')}} : </span>
              <span class="medium-font numbers-font primaryText--text mx-2"></span>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="3" class="d-flex align-center justify-center py-0"> 
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
    </v-card>
    </div>
</template>

<script>
import quantityBtn from '@/components/buttons/plusNegativeBtn'
import { mapState } from 'vuex'
import { removeTObasket } from '@/views/shop/models/basket'
import quatity from '@/components/buttons/quantityBtn'
export default {
  data: () => ({
    count_basket: 0,
    show: false
  }),
    components: {
      quantityBtn,
      quatity,
    },
    computed: {
      ...mapState({
        products: state => state.basket.basket
      }),
    },
    methods: {
      count (count) {
        this.count_basket = count
      },
      remove_item (product) {
        removeTObasket({ product_id: product.id, org_id: product.org_id, price: this.total})
      },
    }
}
</script>