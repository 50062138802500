<template>
  <div>
    <v-card width="250" class="pa-2 card flat-card">
      <v-row class="pa-2">
        <v-img width="190" height="200" src="@/assets/images/5.jpg"></v-img>
        <discount/>
      </v-row>
      <v-row class="mx-1">
          <div class="title-container">
              <span>مرغ 8 تکه پوست کنده</span>
          </div>
      </v-row>
      <v-row>
        <v-col cols="7" class="py-1">
          <span class="font">قیمت هر کیلوگرم:</span>
        </v-col>
        <v-col class="py-1 d-flex justify-end">
          <span class="font">20,000</span>
          <span class="medium-font mr-1">تومان</span>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="7" class="py-1">
          <span class="font">قیمت بسته:</span>
        </v-col>
        <v-col class="py-1 d-flex justify-end">
          <span class="font accentText--text">30,000</span>
          <span class="medium-font accentText--text mr-1">تومان</span>
        </v-col>
      </v-row>
      <v-row class="mx-3 mb-3 d-flex justify-end">
          <span class="font mr-3 muted--text text-decoration-line-through ml-5">38,000</span>
      </v-row>
      <v-btn block depressed class="primary" dark>افزودن به سبد خرید </v-btn>
    </v-card>
  </div>
</template>
<script>
import discount from '@/components/labels/discountLabel'
export default {
    components: {
        discount
    }
}
</script>