<template>
  <div>
    <navbar/>
    <submenu @open_cat='open_cat'/>
    <div class="fixed-alert-adminPanel">
      <alert/>
    </div>
    <div class="section">
    <adminpanel class="display-none"/>
    <v-main class="main-container">
      <v-container fluid>
        <div>
          <v-row class="mx-2">
            <v-col cols="12">
              <order/>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-main>
    </div>
    <drawer :openDrawer="drawer"/>
  </div>
</template>
<style lang="scss" scoped>
.section{
  padding-top: 30px;
}
</style>
<script>
import navbar from '@/components/navbar/actionBar'
import submenu from '@/components/navbar/subMenu'
import adminpanel from '@/components/adminPanel/drawer'
import order from '@/components/adminPanel/myOrdersTab'
import drawer from '@/components/drawers/ordinateDrawer'
import { getFactors } from '@/views/adminPanel/models/factor'
export default {
  components: {
    navbar,
    submenu,
    adminpanel,
    order,
    drawer
  },
  data: () => ({
    drawer: false,
  }),
   created () {
     getFactors()
   },
  methods:{
    open_cat (open) {
      this.drawer = open
    }
  }
}
</script>