<template>
  <div class="d-flex flex-column align-center my-7">
    <v-row class="pa-2 d-flex justify-center">
      <v-img width="100" :src="logo"></v-img>
    </v-row>
    <v-row class="d-flex flex-column">
      <div>
        <alert/>
      </div>
      <v-form @submit.prevent="register()" ref="form_register">
      <v-card class="card flat-card pa-3" width="400">
        <v-row>
          <v-col class="pb-0">
            <span class="primaryText--text lg-font">ثبت نام</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pt-1">
            <v-text-field
            :rules="[ rules.count]"
            v-model="user_data.name"
            label="نام"
            maxlength="21" type="text"
            required/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pt-1">
            <v-text-field
            maxlength="21"
            type="text"
            :rules="[rules.count]"
            v-model="user_data.lastname"
            label="نام خانوادگی"
            required/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pt-1">
            <v-text-field
            :rules="[ rules.required, rules.phone, rules.type_number ]"
            v-model="user_data.mobile"
            label="شماره همراه*"
            maxlength="11" type="tell"
            required/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pt-1">
            <v-text-field
            :rules="[ rules.type_number ]"
            v-model="user_data.national_code"
            label="کد ملی"
            maxlength="10" type="tell"
            required/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pt-1">
            <v-text-field
            :rules="[ rules.required, rules.count, rules.password ]"
            v-model="user_data.password"
            label="رمز عبور*" 
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"/>
          </v-col>
        </v-row>
        <captcha @capchaing="capchaing"/>
        <v-card-actions class="d-flex flex-column mt-3 px-0">
          <v-btn @click="check" :disabled="submit_btn" class="primary button w-100" type="submit">
            عضویت
          </v-btn>
          <span @click="go_to_login" class="d-block text-center mt-4 medium-font info--text cursor-pointer">قبلا ثبت نام کرده ام</span>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-row>
  </div>
</template>
<script>
import captcha from '@/components/captcha/captcha'
import { alertMessage } from '@/assets/messageAlert'
import { toEnglishDigits } from '@/models/changeType'
import { register } from './models/auth'
import alert from '@/components/alerts/alert'
import { mapState } from 'vuex'
import store from '@/store'
export default {
  components: {
    captcha,
    alert
  },
  data: () => ({
    activePicker: null,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    showPassword: false,
    logo: '',
    cap: false,
    user_data: {
      birthday: '',
      national_code: '',
      name: '',
      lastname: '',
      mobile: '',
      password: '',
    },
    rules: {
      required: null,
      count: v => {
        if (v) {
          v.length <= 21 || alertMessage['MaxCharacters']
        }
      },
      phone: v => {
       if (v) {
          const mobile = toEnglishDigits(v)
          return mobile.slice(0,2) == '09' || alertMessage['typePhone']}
       },
      type_number: value => {
        if (value) {
          const mobile = toEnglishDigits(value)
          const pattern = /^[0-9]+$/
          return pattern.test(mobile) || alertMessage['type_number']
        }
      },
      password: v => v.length >= 6 || alertMessage['min_password']
    },
  }),
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  created () {
    this.logo = localStorage.getItem('logo_site')
  },
  computed: {
    ...mapState({
      submit_btn: state => state.loadingSubmit
    }),
    data () {
      const d = new Date(this.date)
      const b = d.toLocaleDateString('fa-IR')
      return b
    }
  },
  methods: {
    capchaing (cap) {
      this.cap = cap
    },
    go_to_login () {
      this.$router.push('/login')
    },
    check () {
      this.rules.required =
      value => !!value || alertMessage['required']
    },
    register () {
      const validation = this.$refs.form_register.validate()
      if (this.cap) {
        if (validation) {
        register({
        first_name: this.user_data.name,
        last_name: this.user_data.lastname,
        mobile: toEnglishDigits(this.user_data.mobile),
        password: toEnglishDigits(this.user_data.password),
        national_code: toEnglishDigits(this.user_data.national_code),
        birthday: toEnglishDigits(this.data)
      })
      }
      } else {
        scrollTo(0,0)
        store.dispatch("alertSuccess",{
      show: true,
        type: 'error',
        typeMessage:'captcha',
        color:"error"
    })
    setTimeout(() => {
      store.dispatch("alertSuccess",{
      show: false,
        type: 'error',
        typeMessage:'network',
        color:"error"
    })
    }, 2500);
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
  }
}
</script>