<template>
    <div>
      <navbar/>
      <submenu @open_cat='open_cat' />
      <!-- <resultnav/> -->
      <div class="section mt-3">
        <div class="fixed-alert">
          <alert/>
        </div>
        <v-cart class="card flat-card">
        <v-container class="pt-7">
        <v-row class="mx-2">
          <!-- <v-col>
            <filters class="mx-auto"/>
          </v-col> -->
          <!-- <v-col >
            <v-row > -->
              <v-col cols="6" md="3" v-for="result in results" :key="result.id">
                <result :result='result' />
              </v-col>
            </v-row>
          <!-- </v-col> 
          </v-row> -->
        </v-container>
        </v-cart>
      </div>
      <cFooter/>
      <drawer :openDrawer="drawer"/>
    </div>
</template>

<script>
import result from '@/components/cards/search/searchResultCard.vue'
import navbar from '@/components/navbar/actionBar.vue'
// import filters from '@/components/cards/search/filterSidebar'
// import resultnav from '@/components/cards/search/resultNav'
import { mapState, Store } from 'vuex'
import submenu from '@/components/navbar/subMenu'
import drawer from '@/components/drawers/ordinateDrawer'
import cFooter from '@/components/footer/footer'
import alert from '@/components/alerts/alert'
export default {
  components: {
    result,
    navbar,
    submenu,
    drawer,
    // filters,
    // resultnav,
    cFooter,
    alert
  },
    data: () => ({
    drawer: false,
  }),
    computed: {
      ...mapState({
        results: state => state.search.result,
        search_fild: state => state.search.searchFild,
        loading: state => state.search.load_page,
        not_result: state => state.search.not_result,
        filter: state => state.search.filter_search_filds,
        count_result: state => state.search.count_result
      })
    },
  methods:{
    open_cat (open) {
      this.drawer = open
    }
  }
}
</script>