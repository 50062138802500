import { render, staticRenderFns } from "./cardSliderV3.vue?vue&type=template&id=1c7a13aa&scoped=true&"
import script from "./cardSliderV3.vue?vue&type=script&lang=js&"
export * from "./cardSliderV3.vue?vue&type=script&lang=js&"
import style0 from "./cardSliderV3.vue?vue&type=style&index=0&id=1c7a13aa&lang=scss&scoped=true&"
import style1 from "./cardSliderV3.vue?vue&type=style&index=1&id=1c7a13aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c7a13aa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VCard,VSkeletonLoader,VSlideGroup,VSlideItem})
