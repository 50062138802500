<template>
  <v-card class="card flat-card">
    <v-tabs v-model="tab">
      <v-tab>{{$t('bill.active')}}</v-tab>
      <v-tab>{{$t('bill.previous')}}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item >
        <div class="mt-2">
          <accordion v-if="activeFactors.length != 0" :factors= 'activeFactors' />
          <div class="d-flex flex-column align-center pa-3" v-if="activeFactors.length == 0">
          <v-img width="80" class="my-3 mx-auto" src="@/assets/icons/shopping-list.svg"></v-img>
          <span class="medium-font">{{$t('bill.noResults')}}</span>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item>
        <accordion v-if="resiveFactors.length != 0" :factors= 'resiveFactors' />
        <div class="d-flex flex-column align-center pa-3" v-if="resiveFactors.length == 0">
        <v-img width="80" class="my-3 mx-auto" src="@/assets/icons/shopping-list.svg"></v-img>
        <span class="medium-font">{{$t('bill.noResults')}}</span>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import accordion from './cargoAccordion'
import { mapState } from 'vuex'
  export default {
    components:{
      accordion
    },
    data: () => ({
      tab: null
    }),
    computed: {
      ...mapState({
        activeFactors: state => state.factors.factors.filter(item => item.status != 6),
        resiveFactors: state => state.factors.factors.filter(item => item.status == 6)
      })
    },
    methods: {
      show () {
        console.log(this.factors)
      }
    }
  }
</script>