<template>
<div class="mt-10">
  <div class="mt-4 mb-4 mx-3">
    <span class="base-font primaryText--text font-weight-bold" v-text="block.block_template.title"></span>
  </div>
  <v-card class="radius-bottom-side flat-card pa-3 mx-3">
    <v-slide-group v-model="model" show-arrows>
      <div v-if="slides.length == 0" class="d-flex">
        <v-slide-item >
          <v-skeleton-loader class="mx-1" v-bind="attrs" type="image, article, actions" width="260" height="400"></v-skeleton-loader>
        </v-slide-item>
        <v-slide-item >
          <v-skeleton-loader class="mx-1" v-bind="attrs" type="image, article, actions" width="260" height="400"></v-skeleton-loader>
        </v-slide-item>
        <v-slide-item >
          <v-skeleton-loader class="mx-1" v-bind="attrs" type="image, article, actions" width="260" height="400"></v-skeleton-loader>
        </v-slide-item>
        <v-slide-item >
          <v-skeleton-loader class="mx-1" v-bind="attrs" type="image, article, actions" width="260" height="400"></v-skeleton-loader>
        </v-slide-item>
        <v-slide-item >
          <v-skeleton-loader class="mx-1" v-bind="attrs" type="image, article, actions" width="260" height="400"></v-skeleton-loader>
        </v-slide-item>
        <v-slide-item >
          <v-skeleton-loader class="mx-1" v-bind="attrs" type="image, article, actions" width="260" height="400"></v-skeleton-loader>
        </v-slide-item>
      </div>
    </v-slide-group>
    <div id="product-carousel">
      <splide :options= "options" :slides = 'slides' class="d-flex">
        <splide-slide v-for="slide  in slides" :key="slide.id">
          <product :product = 'slide'/>
        </splide-slide>
      </splide>
    </div>
  </v-card>
</div>
</template>
<style lang="scss" scoped>
.v-menu__content.theme--light.menuable__content__active{
  z-index: 0 !important;
}
</style>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import product from '../cards/product/sliderCard'
import { mapState } from 'vuex'
export default {
  components: {
    product,
    SplideSlide,
    Splide
  },
  props: ['block'],
  computed: {
    ...mapState({
      productBlock: state => state.home.blockProduct
    }),
    slides () {
      let products = []
      for (const product of this.productBlock) {
        if (product.parentID == this.$props.block.id) {
          products = product.products
          return products
        }
      }
      return products
    }
  },
  data: () => ({
    blockID: '',
    items: [],
    benched: 0,
    options: {
      type: 'slide',
      rewind : true,
      trimSpace: true,
      width: '100%',
      perPage: 4,
      arrows: true,
      drag: true,
      pagination: false,
      waitForTransition: false,
      perMove: 1,
      gap: '0.5rem',
      start: 0,
      focus: 'center',
      direction: 'rtl'
    },
  }),
}
</script>
<style lang="scss" scoped>
.grid{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 95%;
  align-items: center;
}
</style>