<template>
  <div>
    <navbar/>
    <submenu @open_cat='open_cat'/>
    <div class="section">
      <v-container fluid class="">
        <div class="fixed-alert">
          <alert/>
        </div>
        <v-row class="mt-1 mx-1">
          <div @click="toStore()" class="d-flex align-center">
            <v-icon class="info--text">{{$t('categories.arrowR')}}</v-icon>
            <span class="info--text click font mx-1">{{$t('basket.backToStore')}}</span>
          </div>
        </v-row>
        <v-row v-if="products.length < 1 && !loading">
          <v-col class="d-flex flex-column justify-center align-center">
            <v-avatar tile size="200">
              <v-img src="@/assets/icons/carts.svg"/>
            </v-avatar>
            <span class="lg-font mt-2">سبد خرید شما خالی است</span>
          </v-col>
        </v-row>
        <v-row v-if="products.length >= 1 && !loading">
          <v-col cols="12" md="8">
            <v-card class="pa-2 card flat-card">
              <div>
                <v-row class="pa-1">
                  <span class="mx-3 mt-2 subtitle-1">{{$t('basket.basket')}}</span>   
                </v-row>
                <v-row class="d-flex justify-space-between align-center mb-1">
                  <v-col class="pb-1">
                    <v-btn @click="remove_all_basket" class="primaryText--text small-button mx-2" text>{{$t('basket.removeAll')}}</v-btn>
                  </v-col>
                  <v-col  class="d-flex justify-end pb-1">
                    <span class="medium-font muted--text mx-3">{{$t('basket.price')}} ({{type}})</span>
                  </v-col>
                  <v-col cols="3" class="d-flex justify-end pb-1">
                    <span class="medium-font muted--text mx-3">{{$t('basket.totalPrice')}} ({{type}})</span>
                  </v-col>
                </v-row>
              </div>
              <v-divider class="mb-3"></v-divider>
              <loader v-if="products.length < 1 && loading"/>
              <thincard/>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <cost class="mb-3" :text="$t('basket.setAddress')"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <cFooter/>
    <drawer :openDrawer="drawer"/>
  </div>
</template>
<style lang="scss" scoped>
.v-btn{
  letter-spacing: 0;
}
</style>
<script>
import navbar from '@/components/navbar/actionBar'
import submenu from '@/components/navbar/subMenu'
import card from '@/components/cards/basket/basketCard'
import cost from '@/components/cards/basket/costBasketCard'
// import offers from '@/components/cards/basket/basketOffersCard'
import thincard from '@/components/cards/basket/basketThinCard'
import { remove_all_basket, viewbasket_products } from './models/basket'
import drawer from '@/components/drawers/ordinateDrawer'
import loader from '@/components/skeletonLoader/basketThinCardLoader'
import cFooter from '@/components/footer/footer'
import alert from '@/components/alerts/alert'
import store from '@/store'
import { mapState } from 'vuex'
export default {
  components: {
    navbar,
    submenu,
   //  card,
    cost,
    // offers,
    thincard,
    // offers,
    // thincard,
    drawer,
    loader,
    cFooter,
    alert
  },
  computed: {
    ...mapState({
      products: state => state.basket.basket,
      loading: state => state.loading_page
    })
  },
  data: () => ({
    type: '',
    drawer: false,
  }),
  created () {
    viewbasket_products()
    let config = localStorage.getItem('config')
    if (config) {
      config = JSON.parse(config)
      this.type = config.currency
    }
  },
  methods:{
    open_cat (open) {
      this.drawer = open
    },
    remove_all_basket () {
      let basket = localStorage.getItem('basket')
      if (basket) {
        basket = JSON.parse(basket)
      }
      for (const item of basket) {
        remove_all_basket({ product_id: item.id, org_id: item.org_id })
      }
      localStorage.removeItem('basket')
      store.dispatch('remove_all_basket')
    },
    toStore(){
      this.$router.push('/')
    }
  }
}
</script>