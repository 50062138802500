<template>
  <div>
    <div class="primary-header white">
      <v-row class="mx-2">
        <v-col sm="6" md="3" class="d-flex align-center">
          <div @click="go_to_home" class="logo-container cursor-pointer">
            <img :src="logo" width="100%"/>
            <!-- <img src="@/assets/akhavan.svg" width="100%"/> -->
          </div>
        </v-col>
        <v-col col="6" md="9" class="d-flex align-center">
          <search/>
        </v-col>
        <!-- <v-col cols="2" class="d-flex justify-end align-center">
          <multilang/>
        </v-col> -->
      </v-row>
    </div> 
  </div>
</template>
<script>
import search from './searchBar'
import { group_tags } from '@/views/shop/models/home'
// import multilang from '../multilang/localeSwitch'
export default {
  components: {
    search,
    // multilang
  },
  data: () => ({
    drawer: false,
    logo: ''
  }),
  created () {
    this.logo = localStorage.getItem('logo_site')
  },
  methods: {
    go_to_home () {
      this.$router.push('/')
    },
    magic_search () {
      group_tags("56459")
    }
  }
}
</script>