<template>
  <v-card class="card flat-card no-border w-100">
    <v-tabs v-model="tab">
      <v-tab v-for="delivery in deliverys.deliveries.deliveries" :key="delivery.solar_date" class="d-flex flex-column">
        <span class="medium-font primaryText--text" v-text="delivery.Day_Title"></span>
        <span class="small-font numbers-font primaryText--text" v-text="delivery.solar_date"></span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="delivery in deliverys.deliveries.deliveries" :key="delivery.solar_date">
        <v-card class="pa-2 card flat-card no-border" width="100%">
          <v-card-text class="mt-2">
              <v-radio-group @change="set_time" v-model="radioGroup">
                <v-row class="mx-2 mb-1">
                  <v-col v-for=" shift in delivery.shifts" :key="shift.Delivery_Shift_ID_PK" cols="3" class="pa-2">
                    <v-row class="d-flex align-center mb-1">
                      <v-col cols="8" class="pa-0">
                      <v-radio class="numbers-font" v-if="shift.had_capacity == 1" :label="shift.Shift_Title" :value="delivery.solar_date +','+ shift.Delivery_Shift_ID_PK"></v-radio>
                      <v-radio class="numbers-font" v-else disabled :label="shift.Shift_Title" :value="delivery.solar_date +','+ shift.Delivery_Shift_ID_PK"></v-radio>
                      </v-col>
                      <v-col class="text-start pa-0">
                      <span v-if="shift.had_capacity == 0" class="primaryText--text caption">{{$t('basket.full')}}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-radio-group>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<style lang="scss" scoped>
.v-tabs-slider-wrapper{
  height: 2px !important;
  left: 0 !important;
  width: 104px !important;
}
</style>
<script>
import { mapState } from 'vuex'
import store from '@/store'
export default {
  data: () => ({
    radioGroup: '',
    tab: true
  }),
  computed: {
    ...mapState({
    deliverys: state => state.basket.delivery_Time
    })
  },
  methods: {
    set_time () {
      const date = this.radioGroup.split(',')
      const Time = {
        date: date[0],
        shift: date[1],
        cargoId: this.deliverys.cargos_id
      }
      store.dispatch('set_time_basket', Time)
    }
  }
}
</script>