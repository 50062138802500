import Vue from 'vue'
import Vuex from 'vuex'
import basket from '../views/shop/store/basket'
import home from '../views/shop/store/home'
import categories from '../views/shop/store/categories'
import factors from '../views/adminPanel/store/factor'
import user from '../views/adminPanel/store/user'
import purse from '../views/adminPanel/store/purse'
import address from '../views/adminPanel/store/address'
import search from '../views/shop/store/search'
import productDetail from '../views/productDetail/store/productsDetail'
// import auth from '@/views/auth/store/auth'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    alert: {
      show: false,
      type: '',
      typeMessage:'',
      color:""
    },
    loadingSubmit: false,
    loading_page: false,
    current_tab: 'userProfile',
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    submit: (state,paylod) => {
      state.loadingSubmit = paylod
    },
    alertSuccess: (state,paylod) => {
      state.alert.type = paylod.type
      state.alert.show = paylod.show
      state.alert.typeMessage = paylod.typeMessage
      state.alert.color = paylod.color
    },
    load_page: (state, paylod) => {
      state.loading_page = paylod
    },
    current_tab: (state, payload) => {
      state.current_tab = payload
    }
  },
  actions: {
    submit: ({commit}, paylod) => {
      commit('submit',paylod)
    },
    alertSuccess: ({commit}, paylod) => {
      commit('alertSuccess',paylod)
    },
    alertError: ({commit}, paylod) => {
      commit('alertError',paylod)
    },
    load_page: ({commit}, paylod) => {
      commit('load_page', paylod)
    },
    current_tab: ({commit}, payload) => {
      commit('current_tab', payload)
    },
  },
  modules: {
    basket,
    home,
    factors,
    user,
    purse,
    address,
    categories,
    search,
    // auth,
    productDetail
  }
})

