import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify';
Vue.use(Vuetify);
import { get_config } from '@/models/changeType';
import axios from 'axios';
let lang_local = true
  let link = document.createElement('link'),
  oldLink = document.getElementById('lang_css')
  link.id = 'lang_css'
  link.rel = 'stylesheet'
  // link.href = `/static/bita-web/style/bitaNetRTL.css`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
let lang = localStorage.getItem('lang')
if (lang) {
  if (lang != 'fa') {
    lang_local = false
    let link = document.createElement('link'),
    oldLink = document.getElementById('lang_css')
    link.id = 'lang_css'
    link.rel = 'stylesheet'
    // link.href = `/static/bita-web/style/bitaNetLTR.css`
    if (oldLink) {
    document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }
}
let config = {} as any
config = localStorage.getItem('config')
if (!config) {
  get_config()
}
const _config = JSON.parse(config)
const themes = JSON.parse(_config.theme)
if (!themes) {
  localStorage.removeItem('config')
  axios.get('/api/Store/Config/get').then(Response => {
    localStorage.setItem('config', JSON.stringify(Response.data.Result))
    location.reload()
  })
}
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.rel = 'stylesheet';
    link.href = `/template/mobile/style/${_config.client_id}.css?v=123456789`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-57x57')
    link.id = 'apple-icon-57x57'
    link.rel = 'apple-touch-icon'
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-57x57.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-60x60');
    link.id = 'apple-icon-60x60';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-60x60.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-72x72');
    link.id = 'apple-icon-72x72';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-72x72.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-76x76');
    link.id = 'apple-icon-76x76';
    link.rel = 'apple-touch-icon';
    link.href =  `/template/mobile/images/icons/${_config.client_id}/apple-icon-76x76.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-114x114');
    link.id = 'apple-icon-114x114';
    link.rel = 'apple-touch-icon';
    link.href =  `/template/mobile/images/icons/${_config.client_id}/apple-icon-114x114.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-120x120');
    link.id = 'apple-icon-120x120';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-120x120.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-144x144');
    link.id = 'apple-icon-144x144';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-144x144.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-152x152');
    link.id = 'apple-icon-152x152';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-152x152.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-180x180');
    link.id = 'apple-icon-180x180';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-180x180.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('android-icon-192x192');
    link.id = 'android-icon-192x192';
    link.rel = 'icon';
    link.href =`/template/mobile/images/icons/${_config.client_id}/android-icon-192x192.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-32x32');
    link.id = 'favicon-32x32';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile/images/icons/${_config.client_id}/favicon-32x32.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-96x96');
    link.id = 'favicon-96x96';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile/images/icons/${_config.client_id}/favicon-96x96.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-16x16');
    link.id = 'favicon-16x16';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile/images/icons/${_config.client_id}/favicon-16x16.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <meta name="msapplication-TileColor" content="#ffffff">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('msapplication-TileColor');
    link.id = 'msapplication-TileColor';
    link.name = 'msapplication-TileColor';
    link.content = "#ffffff"
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }

  // <meta name="msapplication-TileImage" content="/ms-icon-144x144.png">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.name = 'msapplication-TileImage'
    link.content = `/template/mobile/images/icon/${_config.client_id}/ms-icon-144x144.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }

  // <meta name="theme-color" content="#ffffff">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.name = 'theme-color'
    link.content = themes.light.primary
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
export default new Vuetify({
    rtl: lang_local,
    icons: {
      iconfont: 'mdiSvg',
    },
    theme: {
        themes: {
        light: themes.light
        // light: {
        //     primary: '#7D88CF',
        //     secondary: '#A4ADBD',
        //     accent: '#D3D8F5',
        //     offLabel: '#3C457C',
        //     error: '#B71C1C',
        //     success: '#2E7D32',
        //     warning: '#FFA000',
        //     info: '#1E88E5',
        //     dark: '#252b31',
        //     light: '#9f7453',
        //     gray: '#828c84',
        //     muted: '#7D7B7C',    
        //     background: '#f5f5f5',
        //     dividers: '#FBF6E8',
        //     rateIcons: '#d8d8d8',
        //     loadingColor: '#3D011D',
        //     appBar: '#FFFFFF', //bottom app bar
        //     numbersBg: '#F5F5F5', //number's background in basket card plus/negative button
        //     titleFont: '#322F2F',
        //     subtitleFont: '#434242',
        //     subheaderFont: '#414141',
        //     primaryText: '#3C457C',
        //     accentText: '#3D011D',
        //     secondaryText: '#3D3E41',
        //     datesColor: '#9F9E9C',
        //     productBtn: '#3C457C',
        //     productBtnText: '#ffffff',
        //     basketBtnFilltext: '#ffffff',
        //     white: '#ffffff',
        //     cardHoverBg: '#f6f7f8',
        //     footer: '#A4ADBD',
        //     footerText: '#fff',
        //     searchInput: '#EAECF5'
        // }
      }
    }
});
