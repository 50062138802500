<template>
    <div>
    <v-card class="pa-2 card flat-card ml-2" :color="color" width="260" height="320">
      <v-row @click="show_product()" class="cursor-pointer">
        <v-col class="d-flex justify-center">
        <div class="cursor-pointer" style="width: 130px; height: 130px">
          <img :src="product.image" style="width: 100%; height: 100%"/>
        </div>
        <discount v-if="product.offer" :offer ='product.offer'/>
        </v-col>
      </v-row>
      <v-row class="mx-3">
        <div @click="show_product()" class="title-container cursor-pointer">
          <span v-text="product.title.slice(0,70)"><span class="medium-font" v-if ="product.title > product.title.slice(0,70)">...</span></span>
        </div>
      </v-row>
      <v-row v-if="product.Is_Bulk == 1" class="mx-3">
        <v-col cols="7" class="pa-0">
          <span class="medium-font">{{$t('product.priceKg')}} : </span>
        </v-col>
        <v-col cols="5" class="pa-0">
          <span class="medium-font numbers-font" v-text="product.priceFormate"></span>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="7" class="pa-0"></v-col>
        <v-col cols="5" class="pa-0">
          <span class="medium-font ml-2 error--text text-decoration-line-through numbers-font" v-if="product.price_gross != product.price && product.price_gross!= 0" v-text="product.priceGrossFormate"></span>
        </v-col>
      </v-row>
      <v-row class="mx-3 mb-3">
        <v-col cols="7" class="pa-0">
          <span class="medium-font">{{$t('product.packagePrice')}} : </span>
        </v-col>
        <v-col cols="5" class="pa-0">
          <span class="primaryText--text medium-font numbers-font" v-if="product.Is_Bulk != 1" v-text="product.priceFormate"></span>
          <span class="primaryText--text medium-font numbers-font" v-if="product.Is_Bulk == 1" v-text="product.price_format_pack"></span>
        </v-col>
      </v-row>
      <v-row class="mx-3">
      <div class="product-card-button">
        <div v-if="!add_basket">
          <btn :slider="product" @open="open"/>
        </div>
        <div class="product-card-button">
          <quantityBtn color="productBtn productBtnText--text" block="true" v-if="add_basket && product.Is_Bulk == 1" :product= 'basket_product' />
        </div>
        <plus @show='open' v-if="add_basket && product.Is_Bulk != 1" :product= 'basket_product'/>
      </div>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import discount from '../../labels/discountLabel'
import btn from '../../buttons/basketBtnOutline'
import quantityBtn from '../../buttons/quantityBtn'
import plus from '../../buttons/plusNegativeBtn'
import { mapState } from 'vuex'
export default {
  props: ['product', 'color'],
  data: () => ({
    add_basket: false,
    basket_product: {}
  }),
  components: {
    discount,
    btn,
    quantityBtn,
    plus
  },
  computed: {
    ...mapState({
      basket: state => state.basket.basket,
    })
  },
  mounted () {
    for (const item of this.basket) {
      if (item.id == this.$props.product.id) {
        this.basket_product = item
        this.add_basket = true
        return
      }
    }
    this.basket_product = this.$props.product
  },
  methods: {
    show_product () {
      this.$router.push(`/productDetail/${this.$props.product.id}`)
    },
    add_basket_product () {
      console.log('click')
    },
    open (open) {
      this.add_basket = open
    },
  }
}
</script>