<template>
  <v-card class="fixed-nav pt-2 px-2 card flat-card">
    <v-row>
      <v-col class="d-flex justify-center">
        <div style="width: 200px;">
          <img :src="logo" style="width:100%">
        </div>
      </v-col>
    </v-row>
    <stepper/>
  </v-card>
</template>
<script>
import stepper from '@/components/stepper/basketStepper'
export default {
  components: {
    stepper
  },
  data: () => ({
    logo: ''
  }),
  created () {
    this.logo = localStorage.getItem('logo_site')
  }
}
</script>