<template>
  <div class="pt-12">
    <v-card class="card flat-card px-3 pb-3 background" width="460" max-height="416" style="overflow-y: scroll">
      <v-row class="mx-2 py-2 background" style="position: sticky; top:0; z-index:1">
        <div class="d-flex justify-space-between" style="width:100%">
        <span class="font">{{$t('bill.items')}} : </span>
        <span class="font numbers-font" v-text="status.total_items"></span>
        </div>

      </v-row>
      <div class="pt-9">
      <v-row justify="center">
        <loader v-if="items.length == 0"/>
        <div v-if="items.length > 0">
        <v-card v-for="item in items" :key="item.id" class="mb-4 card flat-card" style="width: 90%">
        <v-row>
        <v-col cols="4" class="pl-0 d-flex align-center justify-center">
        <v-list-item-avatar size="90" tile class="mx-1" color="white">
          <v-img :src="item.image"/>
        </v-list-item-avatar>
        </v-col>
        <v-col cols="8" class="my-auto">
            <div class="mt-2">
            <span class="medium-font">{{$t('product.productName')}} : </span>
            <span class="medium-font mx-1" v-text="item.title.slice(0,50)"><span v-if="item.title > item.title.slice(0,50)" >...</span></span>
            </div>
            <div>
            <span v-if="item.is_bulk != 1" class="medium-font">{{$t('product.number')}} : </span>
            <span v-if="item.is_bulk == 1" class="medium-font">{{$t('product.amount')}} : </span>
            <span class="medium-font mx-1 numbers-font" v-text="item.count"></span>
            </div>
            <div>
            <span class="medium-font">{{$t('product.unitPrice')}} : </span>
            <span class="medium-font mx-1 numbers-font" v-text="item.price"></span>
            </div>
            <div>
            <span class="medium-font">{{$t('product.discount')}} : </span>
           <span class="medium-font mx-1 error--text numbers-font" v-text="item.offer"></span>
            </div>
          <div>
            <span class="medium-font">{{$t('product.price')}} : </span>
              <span class="font numbers-font" v-text="item.toll_price"></span>
          </div>
        </v-col> 
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
        <span class="medium-font mr-2">{{$t('product.supplier')}} : </span>
        <v-spacer></v-spacer>
        <span class="medium-font mr-auto" v-text="item.org_title"></span>
        </v-card-actions>
        </v-card>
        </div>
      </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import loader from '@/components/skeletonLoader/cargoDetailLoader'
export default {
  computed: {
    ...mapState({
      status: state => state.factors.cargo_status,
      items: state => state.factors.cargoItems
    })
  },
  components: {
    loader
  }
}
</script>