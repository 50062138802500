<template>
    <div>
        <v-card class="d-flex flex-column card flat-card">
            <v-row class="pa-2 mx-2 mt-1 d-flex align-center">
                <span class="base-font" v-text="cargos.cargo_config_title"></span>
            </v-row>
            <v-divider class="mx-5 mt-2"></v-divider>
            <v-row class="pa-2 mx-2 mb-3 mt-2">
                <v-avatar v-for="item in items" :key="item.id" tile size="80" class="mx-2">
                <v-img :src="item.Prod_Image_With_Path"></v-img>
                </v-avatar>
            </v-row>
            <v-divider class=" mx-5"></v-divider>
            <v-row class="my-3 mx-3">
                <delivery/>
            </v-row>
        </v-card>
    </div>
</template>
<script>
import delivery from '@/components/tabMenu/deliveryTimeTab'
import { mapState } from 'vuex'
export default {
    components: {
        delivery
    },
    computed: {
        ...mapState({
          items: state => state.basket.items_basket,
          cargos: state => state.basket.delivery_Time.deliveries
        })
    }
}
</script>