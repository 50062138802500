import axios from 'axios'
import Store from '@/store/index'
import router from '@/router'
const token = localStorage.getItem('access_token')

export function getAddress () {
    axios.get('/api/Store/Basket/getAddresses',{
      headers: {
      'Authorization': `Bearer${token}`
      }
    }).then(Response => {
      if (Response.data.Result.error) {
        Store.dispatch("alertSuccess",{
          show: true,
            type: 'error',
            typeMessage:'network',
            color:"error"
        })
        setTimeout(() => {
          Store.dispatch("alertSuccess",{
            show: false,
            type: 'success',
            typeMessage:'network',
            color:"success"
          })
        }, 3000);
        return
      }
      Store.dispatch('setAddressUser', Response.data.Result)
    }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

  export function viewAddressUser (addressID: '') {
    axios.get('/api/Store/Basket/getAddresses',{
      headers: {
      'Authorization': `Bearer${token}`
      }
    }).then(Response => {
      if (Response.data.Result.error) {
        Store.dispatch("alertSuccess",{
          show: true,
            type: 'error',
            typeMessage:'network',
            color:"error"
        })
        setTimeout(() => {
          Store.dispatch("alertSuccess",{
            show: false,
            type: 'success',
            typeMessage:'network',
            color:"success"
          })
        }, 3000);
        return
      }
      for (const address of Response.data.Result) {
        if (address.id === addressID) {
          Store.dispatch('viewAddressUser', address)
        }
      }
    }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

export function setAddress (address: {}) {
  Store.dispatch('load_page', true)
    axios.post('/api/Store/Basket/setAddress', address,{
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
      Store.dispatch('load_page', false)
      if (Response.data.Result.error) {
        Store.dispatch("alertSuccess",{
          show: true,
            type: 'success',
            typeMessage:'network',
            color:"success"
        })
        setTimeout(() => {
          Store.dispatch("alertSuccess",{
            show: false,
            type: 'success',
            typeMessage:'network',
            color:"success"
          })
        }, 3000);
        return
      }
        if (Response.data.Result.success == 1) {
          getAddress()
        }
    }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

export function deletedAddress (addressID: '') {
    axios.post('/api/Store/Basket/deleteAddress', 
    {
        address_id: addressID
    },{
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
      if (Response.data.Result.error) {
        Store.dispatch("alertSuccess",{
          show: true,
            type: 'error',
            typeMessage:'network',
            color:"error"
        })
        setTimeout(() => {
          Store.dispatch("alertSuccess",{
            show: false,
            type: 'success',
            typeMessage:'network',
            color:"success"
          })
        }, 3000);
        return
      }
        if (Response.data.Result == true) {
            location.reload()
        }
    }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}