import axios from 'axios'
let config = {} as any
config = localStorage.getItem('config')
if (!config) {
  let config_defult = localStorage.getItem('config')
  if (config_defult) {
      config = JSON.parse(config_defult)
  }
}
export async function get_config () {
    const config = localStorage.getItem('config')
    if (!config) {
        axios.get('/api/Store/Config/get').then(Response => {
            localStorage.setItem('config', JSON.stringify(Response.data.Result))
            axios.post('/api/Social/Galleries/getGalleryItems',{
              gallery_id: Response.data.Result.logo_galler_id
            }).then(Response => {
              localStorage.setItem('logo_site', Response.data.Result[0].image)
              if (Response.data.Result[1]) {
                localStorage.setItem('logo_site2', Response.data.Result[1].image) 
              }
              if (Response.data.Result[2]) {
                localStorage.setItem('logo', Response.data.Result[2].thumbnail)
              }
              location.reload()
            })
        })
    }
}


type priceToconfig = {
  price: any;
  Tax_price: string;
}
type offer = {
    price: string;
    gross_price: string;
    typeToChange: string;
    TypeToJoin: string;
  }

export function changeTypePrice_format(priceToconfig: priceToconfig) {
    let TaxPrice = 0
    const _config = JSON.parse(config)
    if (priceToconfig.Tax_price) {
        TaxPrice = (parseInt(priceToconfig.Tax_price) * 100) / 100 
    }
    const price = (parseInt(priceToconfig.price) * 100) / 100
    let changedPrice = price
    if (_config.show_full_price_on_site == 1 && changedPrice != 0) {
        changedPrice = changedPrice + TaxPrice
    }
    if (_config.currency_type == 2) {
        changedPrice = changedPrice / 10
    }
    let numParts = (changedPrice.toFixed(0)).toString().split(".")
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return numParts.join(".") +' '+ _config.currency
}

export function changeTypePrice_format_gross(priceToconfig: priceToconfig) {
    let TaxPrice = 0
    const _config = JSON.parse(config)
    if (priceToconfig.Tax_price) {
        TaxPrice = (parseInt(priceToconfig.Tax_price) * 100) / 100 
    }
    const price = (parseInt(priceToconfig.price) * 100) / 100
    let changedPrice = price
    if (_config.show_full_price_on_site == 1 && changedPrice != 0) {
        changedPrice = changedPrice + TaxPrice
    }
    if (_config.currency_type == 2) {
        changedPrice = changedPrice / 10
    }
    let numParts = (changedPrice.toFixed(0)).toString().split(".")
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return numParts.join(".")
}

export function changeTypePrice_range(priceToconfig: priceToconfig) {
    let TaxPrice = 0
    const _config = JSON.parse(config)
    if (priceToconfig.Tax_price) {
        TaxPrice = (parseInt(priceToconfig.Tax_price) * 100) / 100 
    }
    const price = (parseInt(priceToconfig.price) * 100) / 100
    let changedPrice = price
    if (_config.show_full_price_on_site == 1 && changedPrice != 0) {
        changedPrice = changedPrice + TaxPrice
    }
    if (_config.currency_type == 2) {
        changedPrice = changedPrice / 10
    }

    return changedPrice
}

export function changeTypePrice(priceToconfig: priceToconfig) {
    let TaxPrice = 0
    const _config = JSON.parse(config)
    if (priceToconfig.Tax_price) {
        TaxPrice = (parseInt(priceToconfig.Tax_price) * 100) / 100 
    }
    const price = (parseInt(priceToconfig.price) * 100) / 100
    let changedPrice = price
    if (_config.show_full_price_on_site == 1 && changedPrice != 0) {
        changedPrice = changedPrice + TaxPrice
    }
    return changedPrice
}

export function changeTypePrice_factor(priceToconfig: priceToconfig) {
    let TaxPrice = 0
    const _config = JSON.parse(config)
    if (priceToconfig.Tax_price) {
        TaxPrice = (parseInt(priceToconfig.Tax_price) * 100) / 100 
    }
    const price = (parseInt(priceToconfig.price) * 100) / 100
    let changedPrice = price
    if (_config.show_full_price_on_site == 0) {
        changedPrice = changedPrice + TaxPrice
    }
    if (_config.currency_type == 2) {
        changedPrice = changedPrice / 10
    }
    let numParts = (changedPrice.toFixed(0)).toString().split(".")
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return numParts.join(".") +' '+ _config.currency
}

export function changeTypePriceFactor (priceToconfig: priceToconfig) {
    let TaxPrice = 0
    const _config = JSON.parse(config)
    if (priceToconfig.Tax_price) {
        TaxPrice = (parseInt(priceToconfig.Tax_price) * 100) / 100 
    }
    const price = (parseInt(priceToconfig.price) * 100) / 100
    let changedPrice = price
    if (_config.show_full_price_on_site == 0 && changedPrice != 0) {
        changedPrice = changedPrice + TaxPrice
    }
    return changedPrice
}

export function changeTypecount(count: any, type: any) {
    let changedcount = count
    if (!type || type == 0) {
        changedcount = parseInt(changedcount)
    }
    return changedcount
}

export function calOffer(offer: offer) {
    let percent = 0
   if (parseInt(offer.gross_price) != 0) {
    switch (offer.typeToChange) {
        case 'percent':
         percent = ((parseInt(offer.gross_price) - parseInt(offer.price)) / parseInt(offer.gross_price)) * 100
            break;
        case 'rial':
         percent = parseInt(offer.price) - parseInt(offer.gross_price)
           break;
    
        default:
            break;
    }
   } else {
     return '0'
   }
   return percent.toFixed(0) + ' ' + offer.TypeToJoin
}

export function toEnglishDigits(str: any) {

    // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
    let e = '۰'.charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function(t) {
        return t.charCodeAt(0) - e;
    });

    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    e = '٠'.charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function(t) {
        return t.charCodeAt(0) - e;
    });
    return str;
}

export function changeTypePurse_format(price: any) {
    const _price = parseInt(price)
    let numParts = (_price.toFixed(0)).toString().split(".")
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return numParts.join(".")
}
export function create_url_image(url: string, width: number, height: number) {
    const domain = url.search("http")
    if (domain != -1) {
        const el = document.createElement('a');
        el.href = url
        const img =  el.pathname
        return `/Image/resize?image=${img}&width=${width}&height=${height}`
    }
  return `/Image/resize?image=${url}&width=${width}&height=${height}`
}
export function create_gallery_resize(gallery: any, width: number, height: number) {
   const result = [] as any
   if (gallery.Result == null) {
       return []
   }
   for (const g of gallery.Result) {
    const domain = g.image.search("http")
    if (domain != -1) {
        const el = document.createElement('a');
        el.href = g.image
        const img =  el.pathname
        const image =  `/Image/resize?image=${img}&width=${width}&height=${height}`
        result.push(image)
    } else {
        const image = `/Image/resize?image=${g.image}&width=${width}&height=${height}`
        result.push(image)
    }
   }
   return result
}