<template>
  <v-card class="pa-2 sticky card flat-card">
    <v-row class="mt-1">
      <v-col class="py-2">
        <span class="subtitle-1 primaryText--text">{{$t('bill.costs')}}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="py-2">
        <span class="medium-font">{{$t('product.totalPrice')}} : </span>   
      </v-col>
      <v-col class="d-flex justify-end py-2">
        <div class="mx-2">
          <span class="medium-font numbers-font mx-1" v-text="gross_basket"></span>
        </div>
      </v-col>
    </v-row>
    

    <!-- <v-row>
      <v-col cols="4" class="py-2">
        <span class="font">مالیات:</span>   
      </v-col>
      <v-col class="d-flex justify-end py-2">
        <div class="ml-2">
          <span class="font ml-1">9,000</span>
          <span class="medium-font">تومان</span>
        </div>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="4" class="py-2">
        <span class="medium-font">{{$t('product.discount')}} : </span>   
      </v-col>
      <v-col class="d-flex justify-end py-2">
        <div class="mx-2">
          <span class="medium-font mx-1 numbers-font" v-text="offer_basket"></span>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mb-2 mt-3"></v-divider>

    <v-row class="py-2">
      <v-col cols="5" class="py-2">
        <span class="medium-font primaryText--text">{{$t('bill.payable')}} : </span>   
      </v-col>
      <v-col class="d-flex justify-end py-2">
        <div class="mx-2">
          <span class="medium-font mx-1 numbers-font primaryText--text" v-text="priceBasket"></span>
        </div>
      </v-col>
    </v-row>
    <v-row class="pa-2">
      <v-btn @click="basket_shop()" :disabled="submit_btn" class="primary button btn-block button" v-text='text'></v-btn>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import {changeTypePrice_format} from '@/models/changeType'
import { basketResiver, submitReciver, setTimeSend } from '@/views/shop/models/basket'
export default {
  props: ['text'],
  computed: {
    ...mapState({
      priceBasket: function (state) {
        const price = changeTypePrice_format({
          price: state.basket.priceBasket,
          Tax_price: ''
        })
        return price
      },
      addressBasket: state => state.basket.addressBasket,
      basket: state => state.basket.basket,
      time_basket: state => state.basket.set_time_basket,
      gross_basket: function (state) {
        const gross = changeTypePrice_format({
          price: state.basket.gross_basket_price,
          Tax_price: ''
        })
        return gross
      },
      offer_basket: function (state) {
        const offer = changeTypePrice_format({
          price: state.basket.offer_all_basket,
          Tax_price: ''
        })
        return offer
      },
      submit_btn: state => state.loadingSubmit,
    })
  },
  methods: {
    basket_shop () {
      const route = this.$router.history.current.name
      switch (route) {
        case 'basket':
          this.$router.push('/receiverData')
          break;
          case 'receiverData':
            if (this.addressBasket) {
              submitReciver(this.addressBasket)
            }
          break;
        case 'deliveryTime':
          if (this.time_basket.length != 0) {
            setTimeSend(this.time_basket)
        } else {
          store.dispatch("alertSuccess",{
      show: true,
        type: 'error',
        typeMessage:'null_Time_basket',
        color:"error"
    })
    setTimeout(() => {
      store.dispatch("alertSuccess",{
      show: false,
        type: 'error',
        typeMessage:'network',
        color:"error"
    })
    }, 2500);
    }
          break;
      }
    }
  }
}
</script>