<template>
  <div>
    <v-card class="pa-2 card flat-card">
      <v-row >
        <v-col sm="6" md="4" lg="3">
            <gallery :slides='product.gallery' :image="product.image" />
          <!-- <v-img width="400" height="400" :src="product.image"></v-img> -->
        </v-col>
        <v-col sm="6" md="8" lg="9">
          <discount :offer='product.offer' v-if="product.offer && product.onhand != 0" />
          <v-row class="mx-3 d-flex align-center">
            <v-col sm="12" md="8" class="pb-0">
              <div class="title-container">
                <span v-text="product.title"></span>
              </div>
            </v-col>
            
          <!-- <v-col class="pa-0 d-flex justify-center align-start">
            <div dir="ltr">
            <rate :config = 'config' :rating ='product.rate'/>
            </div>
          </v-col> -->
          </v-row>
          <v-row class="mx-3 mb-2">
            <v-col v-if="product.Brand_Title">
              <v-row >
                <v-col>
                  <span class="medium-font">{{$t('product.brand')}} : </span>
                </v-col>
                <v-col>
                  <span class="primaryText--text font-italic medium-font mx-2" v-text="product.Brand_Title"></span>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="12" md="12" lg="4">
              <v-row >
                <v-col cols="4" md="4" class="pt-0">
                  <span class="medium-font">{{$t('product.category')}} : </span>
                </v-col>
                <v-col class="pt-0">
                  <span class="primaryText--text font-italic medium-font " v-text="product.group.title"></span>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col sm="6" md="4" lg="6">
              <v-row>
                <v-col cols="4">
                  <span class="medium-font">{{$t('product.packageType')}} : </span>
                </v-col>
                <v-col>
                  <span class="primaryText--text font-italic medium-font mx-2" v-text="product.unit_title"></span>
                </v-col>
              </v-row>
            </v-col> -->
          </v-row>
          <v-row class="mx-3">
            <div v-if="product.Is_Bulk == 1" class="my-2">
              <span class="font">{{$t('product.netWeight')}} : </span>
              <span class="font mx-2 numbers-font" v-text="product.Packing_Weight"></span>
            </div>
          </v-row>
          <v-row v-if="product.Is_Bulk == 1" class="d-flex align-center mx-3">
            <span class="font">{{$t('product.priceKg')}} : </span>
            <div class="mx-4">
              <span class="font numbers-font mx-1" v-text="product.priceFormate"></span>
            </div>
          </v-row>

          <v-row class=" mx-3">
            <v-col>
              <v-row>
                <div class="mt-2">
                  <v-icon class="muted--text mx-2">mdi-store-outline</v-icon>
                  <span class="font muted--text">{{$t('product.supplier')}} : </span>
                  <span class="font muted--text" v-text="product.org_title"></span>
                </div>
              </v-row>
              <v-row>
                <div class="my-3">
                  <v-icon class="muted--text mx-2">mdi-warehouse</v-icon>
                  <span class="muted--text font">{{$t('product.condition')}} : </span>
                  <span v-if="product.onhand != 0" class="muted--text font mx-2">{{$t('product.available')}}</span>
                  <span v-if="product.onhand == 0" class="muted--text font mx-2">{{$t('product.unAvailable')}}</span>
                </div>
              </v-row>
            </v-col>
            <v-col sm="12" md="6" v-if="product.onhand != 0">
              <v-row>
                <v-col class="d-flex flex-column">
                  <div class="d-flex align-center">
                    <span class="font mx-2">{{$t('product.cost')}} : </span>
                    <span v-if="product.Is_Bulk != 1 && product.onhand != 0" class="text-h6 numbers-font primaryText--text" v-text="product.priceFormate"></span>
                    <span v-if="product.Is_Bulk == 1 && product.onhand != 0" class="text-h6 numbers-font primaryText--text" v-text="product.price_format_pack"></span>
                    <span v-if="product.onhand == 0" class="text-h6 numbers-font primaryText--text" >ناموجود</span>
                  </div>
                  <div class="d-flex justify-center align-center">
                    <span v-if="product.priceGross != product.price && product.priceGross != 0" class="font text-decoration-line-through error--text font-weight-bold" v-text="product.priceGrossFormate"></span>
                    <span v-if="product.priceGross != product.price && product.priceGross != 0" class="font font-weight-bold " style="color: transparent">تومان</span>
                  </div>
                </v-col>
                <v-col>
                  <div @click="add_basket = !add_basket">
                    <btn v-if="!add_basket" :slider='product_basket'/>
                  </div>
                  <quantityBtn v-if="add_basket && product.Is_Bulk == 1" color="primary" :product='product_basket'/>
                  <plus @show="show_open" v-if="add_basket && product.Is_Bulk != 1" :product='product_basket'/>
                </v-col>
              </v-row>
            </v-col>
            
          </v-row>
        <!-- <v-row class="mx-3 mb-3 d-flex align-center">
          <div>
            <span>مقدار:</span>
          </div>
          <div class="d-flex justify-start mr-3">
            <quantity title="2 عدد" height="30" color="accent" v-if="!show" @showOpen ='showOpen'/>
            <qinput v-if="show" height="30"/>
          </div>
        </v-row> -->
        

        <v-row class="mx-3 d-flex flex-column justify-center">
          <!-- <div class="mt-2">
            <v-icon class="muted--text ml-2">mdi-truck-fast-outline</v-icon>
            <span class="muted--text font">امکان حمل سریع</span>
          </div> -->
          
        </v-row>
        <v-divider class="my-3 mx-2"></v-divider>
        <v-row class="mx-3">
          <div class="mt-2">
            <span class="mx-2">{{$t('product.description')}} : </span>
            <div v-if="showLess" class="mb-2">
              <p class="caption detail-line-break mb-2" v-html="product.description"></p>
              <span @click="showLess = !showLess" class="info--text medium-font cursor-pointer">نمایش بیشتر</span>
            </div>
            <div v-if="!showLess">
              <p class="caption mb-2" v-html="product.description"></p>
              <span @click="showLess = !showLess" class="info--text medium-font cursor-pointer">بستن</span>
            </div>
          </div>
        </v-row>
        <v-row>
          <div v-for="tag in product.tags" :key="tag.id" class="mx-1 mt-1">
            <v-chip @click="search_tag(tag.id)" class="cursor-pointer" small v-text="tag.title"></v-chip>
          </div>
        </v-row>
        <!-- <v-row v-if="product.onhand != 0" class="mx-3 d-flex align-center justify-end ">
          <div class="mx-4 d-flex align-center">
            <span class="font mx-2">{{$t('product.cost')}} : </span>
            <div>
              <span v-if="product.Is_Bulk != 1 && product.onhand != 0" class="text-h6 numbers-font primaryText--text" v-text="product.priceFormate"></span>
              <span v-if="product.Is_Bulk == 1 && product.onhand != 0" class="text-h6 numbers-font primaryText--text" v-text="product.price_format_pack"></span>
              <span v-if="product.onhand == 0" class="text-h6 numbers-font primaryText--text" >ناموجود</span>
            </div>
          </div>
          <div>
          <div @click="add_basket = !add_basket">
            <btn v-if="!add_basket" :slider='product_basket'/>
          </div>
          <quantityBtn v-if="add_basket && product.Is_Bulk == 1" color="primary" :product='product_basket'/>
          <plus @show="show_open" v-if="add_basket && product.Is_Bulk != 1" :product='product_basket'/>
          </div>
        </v-row>
        <v-row  class="d-flex justify-end">
          <v-col  cols="6" class="d-flex justify-center pt-0">
            <span v-if="product.priceGross != product.price && product.priceGross != 0" class="medium-font text-decoration-line-through error--text font-weight-bold" v-text="product.priceGrossFormate"></span>
            <span v-if="product.priceGross != product.price && product.priceGross != 0" class="font font-weight-bold " style="color: transparent">تومان</span>
          </v-col>
        </v-row> -->
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import rate from '@/components/rate/rating'
import discount from '@/components/labels/discountLabel'
import btn from '../../buttons/basketBtnOutline'
import quantityBtn from '../../buttons/quantityBtn'
import plus from '../../buttons/plusNegativeBtn'
import { mapState } from 'vuex'
import { viewbasket_products } from '@/views/shop/models/basket'
import store from '@/store'
import gallery from '@/components/sliders/productDetailGallery'
import { search_product_tag } from '@/views/shop/models/search'
export default {
  components: {
    // rate,
    discount,
    btn,
    quantityBtn,
    plus,
    gallery
  },
  data: () => ({
    add_basket: false,
    config: {
      width: 20,
      increment:0.5,
      rounded:true,
      showRating:false,
      readonly:true,
    },
    show: false,
    product_basket: {},
    showLess: true
  }),
  created () {
    const basket = localStorage.getItem('basket')
      if (basket) {
        store.dispatch('basket_local', JSON.parse(basket))
      } else {
        viewbasket_products()
      }
  },
  computed: {
    ...mapState({
      product: function (state) {
        const product = state.basket.product_detail
        this.product_basket = product
        return product
      },
      basket:state => state.basket.basket,
      similar: state => state.basket.similarProducts,
    })
  },
  watch: {
    product: {
      handler: function () {
        for (const item of this.basket) {
      if (this.product.id == item.id ) {
        this.product_basket = item
        this.add_basket = true
        return
      }
      this.product_basket = this.product
    }
      }
    }
  },
  methods: {
    showOpen (show) {
      this.show = show
    },
    show_open (open) {
      this.add_basket = open
    },
    search_tag (tag_id) {
      const filters = {}
          filters.page = 1
          filters.tags = tag_id
          localStorage.setItem('filter_search', JSON.stringify(filters))
          search_product_tag(filters)
    }
  }
}
</script>