<template>
  <div>
    <v-card class="card flat-card">
      <v-row>
        <v-col cols="3">
          <v-img width="200" height="200" src="@/assets/images/3.jpg"></v-img>
        </v-col>
        <v-col cols="7">
          <v-row>
          <div class="title-container ml-0">
            <span>یچیزی</span>
          </div>
          </v-row>
          <v-row>
          <div>
            <span class="medium-font subheaderFont--text">
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.
                چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز 
                و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. 
            </span>
          </div>
            </v-row>
            <v-row class="mt-4">
            <v-col cols="4" class="d-flex align-center justify-start pa-2">
            <quatity v-if="!show" @showOpen ='showOpen' color="accent" height= '30' title="تعداد"/> 
            <qinput v-if="show" class="ml-2" height="30"/>
            </v-col>
            <v-col cols="2" class="d-flex align-center justify-start pa-2">
            <span class="primaryText--text medium-font ml-4">{{$t('product.delete')}}</span>
            </v-col>
            <v-col cols="6" class="d-flex align-center justify-start pa-2">
            <span class="primaryText--text medium-font">{{$t('product.compare')}}</span>
            </v-col>

          </v-row>
        </v-col>
        <v-col class="d-flex justify-end">
            <span class="subtitle-1 primary--text numbers-font">104,954</span>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
    </v-card>
  </div>
</template>
<script>
import quatity from '@/components/buttons/quantityBtn'
import qinput from '@/components/forms/quantityInput'
export default {
    components: {
      quatity,
      qinput
    },
    data: () => ({
      show: false
    }),
    methods: {
      showOpen (show) {
        this.show = show
      }
    }
}
</script>