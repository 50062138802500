<template>
  <div class="label-wrapper text-center offLabel">
    <div class="off-label white text-center">
      <span class="offLabel--text font-weight-bold numbers-font" v-text="offer"></span>
    </div>
    <span class="white--text small-font">{{$t('product.discount')}}</span>
  </div>
</template>
<style lang="scss" scoped>
.label-wrapper{
  border: 2px solid #D32F2F;
  overflow: hidden;
  border-radius: 6px;
  margin: 9px 0px 0;
  font-size: 14px;
  left: 11px;
  right: auto;
  position: absolute;
  width: 40px;
  height: 42px;
  top: 0px;
  .off-label{
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}
</style>
<script>
export default {
  props: ['offer']
}
</script>