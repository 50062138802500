<template>
    <v-card class="pa-2 card flat-card" width="250" height="400">
      <v-row class="pa-2">
        <v-col class="pa-0 d-flex justify-center">
          <div @click="show_product()" class="cursor-pointer" style="width: 190px; height: 210px">
            <img :src="result.image" style="width: 100%; height:100%"/>
          </div>
        </v-col>
      </v-row>
      <v-row class="pa-2">
        <v-col class="py-0">
        <div @click="show_product()" class="title-container cursor-pointer">
          <span class="medium-font" v-text="result.title"></span>
        </div>
        </v-col>
      </v-row>
      <v-row v-if="result.Is_Bulk == 1" class="mx-3">
        <v-col cols="7" class="pa-0">
          <span class="font">{{$t('product.priceKg')}} : </span>
        </v-col>
        <v-col cols="5" class="pa-0">
          <span class="font numbers-font" v-text="result.priceFormat"></span>
        </v-col>
      </v-row>
      
      <v-row class="mx-3">
        <v-col cols="7" class="pa-0">
          <span class="medium-font">{{$t('product.packagePrice')}} : </span>
        </v-col>
        <v-col cols="5" class="pa-0">
          <span class="primaryText--text medium-font numbers-font" v-if="result.Is_Bulk != 1" v-text="result.priceFormat"></span>
          <span class="primaryText--text medium-font numbers-font" v-if="result.Is_Bulk == 1" v-text="result.price_format_pack"></span>
        </v-col>
      </v-row>
      <v-row v-if="result.price_gross != result.price && result.price_gross!= 0" class="d-flex justify-center">
        <v-col cols="5" class="pa-0 d-flex justify-center">
          <span class="medium-font text-decoration-line-through error--text font-weight-bold" v-text="result.price_grossFormat"></span>
          <span class="font font-weight-bold " style="color: transparent">تومان</span>
        </v-col>
      </v-row>
      <v-row class="mx-3 mb-3 ">
        <div class="product-card-button">
        <div v-if="!add_basket">
          <btn :slider='result' @open="open"/>
        </div>
        <div class="product-card-button">
          <quantityBtn color="productBtn productBtnText--text" block="true" v-if="add_basket && result.Is_Bulk == 1" :product= 'basket_product' />
        </div>
        <plus v-if="add_basket && result.Is_Bulk != 1" :product= 'basket_product'/>
      </div>
      </v-row>
  </v-card>
</template>
<script>
import rate from '@/components/rate/rating'
import quantityBtn from '../../buttons/quantityBtn'
import plus from '../../buttons/plusNegativeBtn'
import btn from '../../buttons/basketBtnOutline'
import { mapState } from 'vuex'
export default {
  props: ['result'],
  components: {
    //   rate
    quantityBtn,
    plus,
    btn
  },
  data: () => ({
    add_basket: false,
    basket_product: {},
    config: {
      width: 20,
      increment:0.5,
      rounded:true,
      showRating:false,
      readonly:true,
    }
  }),
  computed: {
    ...mapState({
      basket: state => state.basket.basket,
    })
  },
  mounted () {
    for (const item of this.basket) {
      if (item.id == this.$props.result.id) {
        this.basket_product = item
        this.add_basket = true
        return
      }
    }
    this.basket_product = this.$props.result

    caches.open('images').then(cache => {
      cache.add(this.$props.result.image)
    })
    
  },
  methods: {
    show_product () {
      this.$router.push(`/productDetail/${this.$props.result.id}`)
    },
    add_basket_product () {
      console.log('click')
    },
    open (open) {
      this.add_basket = open
    }
  }
}
</script>