<template>
  <div>
    <v-card class="pa-3 card flat-card" color="background">
      <v-row align="center">
        <!-- <v-col cols="12" md="2" class="d-flex align-center justify-center">
          <div style="width: 100px">
            <v-img src="@/assets/icons/greeting.svg"/>
          </div>
        </v-col> -->
        <v-col cols="5" class="invite-code d-flex">
          <v-text-field outlined dense readonly hide-details :label="$t('inviteFriend.yourCode')" :value="invite.invite"/>
          <div class="mx-2">
            <div v-if="copy" class="copy-alert py-2 px-3 mb-3">
              <span class="medium-font white--text">{{$t('inviteFriend.copied')}}</span>
            </div>
            <v-btn class="primary button invite-btn" @click="copyStringToClipboard(digit)">{{$t('inviteFriend.copyCode')}}</v-btn>
          </div>
        </v-col>
        <v-divider vertical class="mx-3"/>
        <v-col>
          <v-row>
            <v-col class="">
              <span class="base-font ">{{$t('inviteFriend.invited')}}</span>
            </v-col>
          </v-row>
          <v-row v-if="invite_list != 'nothing'">
            <v-card class="pa-3 card flat-card" >
              <v-row v-for="list in invite_list" :key="list.Customer_ID">
                <v-col>
                  <v-avatar class="40">
                    <v-img :src="list.Photo_Path"/>
                  </v-avatar>
                  <span class="medium-font mx-2" v-text="list.Nick_Name"></span>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row v-if="invite_list == 'nothing'">
            <v-card class="pa-3 card flat-card w-100 ">
              <v-row>
                <v-col class="d-flex flex-column align-center justify-center">
              <v-avatar tile size="70">
                <v-img src="@/assets/icons/chat.svg"/>
              </v-avatar>
              <span class="medium-font ">کسی با کد دعوت شما ثبت نام نکرده است</span>
            </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-col>
        <!-- <v-col>
          <span class="font">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere, praesentium deleniti. Cum voluptate aspernatur architecto illo quos. Ullam numquam dignissimos dolorem laudantium, dicta vero quasi corporis adipisci illum at? Vero?</span>
        </v-col> -->
      </v-row>
      <!-- <v-row id="inviteFriend" justify="center">
        <v-col cols="5" class="invite-code d-flex">
          <v-text-field outlined dense :label="$t('inviteFriend.yourCode')" v-model="code"/>
          <div class="mx-2">
            <div v-if="copy" class="copy-alert py-2 px-3 mb-3">
              <span class="medium-font white--text">{{$t('inviteFriend.copied')}}</span>
            </div>
            <v-btn class="primary button invite-btn" @click="copyStringToClipboard(digit)">{{$t('inviteFriend.copyCode')}}</v-btn>
          </div>
        </v-col>
        <v-col class="invite-code d-flex">
          <v-text-field outlined dense :label="$t('inviteFriend.yourLink')" v-model="link"/>
          <v-btn class="primary button small-button invite-btn">{{$t('inviteFriend.copyLink')}}</v-btn>
        </v-col>
      </v-row> -->
    </v-card>
  </div>
</template>
<style lang="scss" scoped>

</style>
<script>
// import invitedFriends from '../accordion/invitedFriendsAcordion'
import { getProfile, getinviteList } from '@/views/adminPanel/models/user'
import { mapState } from 'vuex'
export default {
  components: {
    // invitedFriends,
  },
  data: () => ({
    copy: false,
    code: "123455",
    link: "https://bitanet.com/hdfsjdifjgsdfsdgajasdfsadasd"
  }),
  methods: {
   copyStringToClipboard (str) {
   // Create new element
   const el = document.createElement('input');
   // Set value (string to be copied)
   el.value = str;
   // Set non-editable to avoid focus and move outside of view
   el.setAttribute('readonly', '');
   el.style = {position: 'absolute', left: '-9999px'};
   document.body.appendChild(el);
   // Select text inside element
   el.select();
   // Copy text to clipboard
   document.execCommand('copy');
   // Remove temporary element
   document.body.removeChild(el);
   this.copy = true
   setTimeout(() => {
     return this.copy = false
   }, 1000);
   }
  },
   computed:{
    ...mapState({
      invite: state => state.user.user,
      invite_list: state => state.user.invite_list
    })
  },
  created () {
    getProfile()
    getinviteList()
  }
}
</script>