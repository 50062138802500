<template>
  <div>
    <v-container fluid>
      <navbar/>
      <submenu @open_cat='open_cat' />
      <div class="section">
        <div class="fixed-alert">
          <alert/>
        </div>
        <product/>
      </div>
      <slider class="mt-7"/>
    </v-container>
    <cFooter/>
    <drawer :openDrawer="drawer"/>
  </div>
</template>
<script>
import navbar from '@/components/navbar/actionBar'
import submenu from '@/components/navbar/subMenu'
import slider from '@/components/sliders/brandsSlider'
import product from '@/components/sliders/productSLiderV3'
import { galleries, getblock, blockList } from './models/home'
import { mapState} from 'vuex'
import store from '@/store'
// import product from '@/components/sliders/productSplideSlide'
import drawer from '@/components/drawers/ordinateDrawer'
import cFooter from '@/components/footer/footer'
import alert from '@/components/alerts/alert'
export default {
  components: {
    navbar,
    submenu,
    slider,
    drawer,
    product,
    cFooter,
    alert
  },
  mounted () {
    let config = JSON.parse(localStorage.getItem('config'))
    localStorage.removeItem('filter_search')
    store.dispatch('productDetail')
    if (this.blockProduct.length < 1) {
      getblock()
      galleries(config.mobile_slider_gallery,'banner')
      galleries(config.brands_gallery,'brands')
    }
  },
  computed: {
    ...mapState({
      loading_page: state => state.loading_page,
      blockProduct: state => state.home.blockProduct,
      blocks: function (state) {
        const blocks = state.home.blocks
        if (this.blockProduct.length < 1) {
          for (const block of blocks) {
            blockList(block.id)
        }
        }
        return blocks
      },
    })
  },
  data: () => ({
    drawer: false,
  }),
  methods:{
    open_cat (open) {
      this.drawer = open
    }
  }
}
</script>