<template>
  <div>
    <v-container fluid>
      <navbar/>
      <submenu @open_cat='open_cat' />
      <div class="panel-section">
        <adminSidebar :currentTab="page"/>
        <adminMenu :currentTab="page"/> 
      </div>
    </v-container>
    <drawer :openDrawer="drawer"/>
  </div>
</template>
<script>
import navbar from '@/components/navbar/actionBar'
import submenu from '@/components/navbar/subMenu'
import drawer from '@/components/drawers/ordinateDrawer'
import adminMenu from '@/components/adminPanel/adminMenu'
import adminSidebar from '@/components/adminPanel/drawer'
import store from '@/store'
export default {
  props: ['page'],
  components: {
    navbar,
    drawer,
    adminMenu,
    submenu,
    adminSidebar
  },
  mounted () {
    store.dispatch("current_tab", this.$props.page)
  },
  data: () => ({
    show: true,
  })
}
</script>