<template>
  <div id="category-slider">
    <div class="d-flex justify-center">
      <splide :options= "options" :slides = 'slides' class="d-flex">
        <splide-slide v-for="slide  in slides" :key="slide.id">
          <div @click="getList(slide.id)" :class="slide.id == selected_list ? 'active_category':''" class="d-flex flex-column justify-center align-center cursor-pointer px-2 py-1 mx-2">
            <v-avatar size="65" class="ml-1">
              <v-img :src="slide.banner"/>
            </v-avatar>
            <span class="medium-font mt-1 text-center block-name">{{slide.title}}</span>
          </div>
        </splide-slide>
      </splide>
    </div>
    <cards-slider v-for="block in blockList" :key="block.id" :block="block" />
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { mapState } from 'vuex'
import { blockList } from '@/views/shop/models/home'
import CardsSlider from './cardSliderV3'
import store from '@/store'
export default {
  components: {
    CardsSlider,
    SplideSlide,
    Splide
  },
  data:() => ({
    active_block: '',
    options: {
      type: 'slide',
      rewind : true,
      trimSpace: true,
      width: '100%',
      perPage: 8,
      arrows: true,
      drag: true,
      pagination: false,
      waitForTransition: false,
      perMove: 1,
      start: 0,
      focus: 'center',
      direction: 'rtl'
    },
  }),
  computed: {
    ...mapState({
      slides: state => state.home.blocks,
      blockList: state => state.home.blockList,
      selected_list: state => state.home.selected_list,
    })
  },
  watch:{
    slides: {
      handler: function () {
        store.dispatch('selected_list',this.slides[0].id)
      }
    }
  },
  methods:{
    getList(id) {
      store.dispatch('selected_list',id)
      blockList(id)
    }
  }
}
</script>