<template>
  <div class="w-100 mb-4">
    <v-form>
      <!-- <v-card class="pa-3 card flat-card mb-4"> -->
        <v-row class="py-3">
          <v-col class="d-flex align-center">
            <span class="medium-font mx-2">{{$t('product.shareOpinion')}}</span>
          </v-col>
          <v-col class="d-flex align-center" dir="ltr">
            <rating @Rate_set = 'Rate_set' class="mt-2" :config = 'config'/>
            <span class="medium-font mx-3">{{$t('product.rateProduct')}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-textarea outlined dense v-model="comment" rows="3" name="text" :label="$t('product.comment')"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <captcha @capchaing="capchaing"/>
          </v-col>
          <v-col class="d-flex justify-end pt-0">
            <v-btn @click="sub_comment()" :disabled="submit_btn" class="primary button">{{$t('product.post')}}</v-btn>
          </v-col>
        </v-row>
      <!-- </v-card> -->
    </v-form>
  </div>
</template>
<script>
import rating from '@/components/rate/rating'
import { add_comment } from "@/views/productDetail/models/productsDetail"
import captcha from '@/components/captcha/captcha'
import { mapState } from 'vuex'
import store from '@/store'
export default {
  props: ['productID'],
  components: {
    rating,
    captcha
  },
  data: () => ({
    rate: 0,
    cap: false,
    config: {
      width: 20,
      increment:0.5,
      rounded:true,
      showRating:false,
      readonly:false,
    },
  }),
  computed: {
    ...mapState({
      submit_btn: state => state.loadingSubmit
    })
  },
  methods: {
    capchaing (val) {
      this.cap = val
    },
    Rate_set (Rate) {
      this.rate = Rate
    },
    sub_comment () {
      const token = localStorage.getItem('access_token')
      if (token) {
        if (this.cap) {
          add_comment({
        product_id: this.$props.productID,
        text: this.comment,
        rate: this.rate
        })
        } else {
          scrollTo(0,0)
        store.dispatch("alertSuccess",{
      show: true,
        type: 'error',
        typeMessage:'captcha',
        color:"error"
    })
    setTimeout(() => {
      store.dispatch("alertSuccess",{
      show: false,
        type: 'error',
        typeMessage:'network',
        color:"error"
    })
    }, 2500);
    }
      } else {
        scrollTo(0,0)
        store.dispatch("alertSuccess",{
      show: true,
        type: 'error',
        typeMessage:'not_login',
        color:"error"
    })
    setTimeout(() => {
      store.dispatch("alertSuccess",{
      show: false,
        type: 'error',
        typeMessage:'network',
        color:"error"
    })
    }, 2500);
    }
    }
  }
}
</script>