import axios from 'axios'
import Store from '@/store/index'
import{ changeTypePrice, changeTypecount, calOffer, changeTypePrice_format } from '@/models/changeType'
const token = localStorage.getItem('access_token')

export function getFactors () {
    Store.dispatch('factors',[])
    axios.get('/api/Store/Factors/list',
    {
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }
    ).then(Response => {
      if (Response.data.Result.error) {
        Store.dispatch("alertSuccess",{
					show: true,
          type: 'error',
          typeMessage:'network',
          color:"error"
        })
        setTimeout(() => {
          Store.dispatch("alertSuccess",{
            show: false,
            type: 'success',
            typeMessage:'network',
            color:"success"
          })
        }, 3000);
        return
      }
        const factors = Response.data.Result
        for (const factor of factors) {
            const factor_info = {
              factor_id: factor.factor_id,
              factor_ref: factor.factor_ref,
              status: factor.status,
              cargo_count: factor.cargo_count,
              created_date: factor.created_date
            }
            Store.dispatch('factorslist',factor_info)
        }
    }).catch (() => {
        // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

export function getcargos (refID: string) {
    Store.dispatch('cargos',[])
    axios.post('/api/Store/Cargoes/list',
    {
        "ref_id": refID
    },
    {
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
        console.log(Response.data)
        if (Response.data.Result.error) {
					Store.dispatch("alertSuccess",{
						show: true,
						type: 'error',
						typeMessage:'network',
						color:"error"
					})
					setTimeout(() => {
						Store.dispatch("alertSuccess",{
							show: false,
							type: 'success',
							typeMessage:'network',
							color:"success"
						})
					}, 3000);
            return
        }
        const cargos = Response.data.Result
        for (const cargo of cargos) {
            const factor_info = {
              cargo_id: cargo.cargo_id_pk
            }
            Store.dispatch('cargoslist',factor_info)
        }
        
    }).catch (() => {
        // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

export function viewCargo (cargoid: string) {
    Store.dispatch('cargosItems',[])
    axios.post('/api/Store/Cargoes/view',
    {
        "cargo_id": cargoid
    },
    {
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
        console.log(Response.data)
        if (Response.data.Result.error) {
					Store.dispatch("alertSuccess",{
						show: true,
						type: 'error',
						typeMessage:'network',
						color:"error"
					})
					setTimeout(() => {
						Store.dispatch("alertSuccess",{
							show: false,
							type: 'success',
							typeMessage:'network',
							color:"success"
						})
					}, 3000);
            return
        }
      const cargoItems = Response.data.Result.Items
      const crago_status = {
        status: Response.data.Result.Header.status,
        total_items: Response.data.Result.Header.total_items
      }
      Store.dispatch('crago_status', crago_status)
      for (const items of cargoItems) {
          const toll_price = changeTypePrice({
              price: items.factor_item_price,
              Tax_price: items.Tax_Price
          })
        const cargo_info = {
          id: items.prod_id,
          is_bulk: items.Is_Bulk,
          image: items.Image_Resize,
          title: items.prod_title,
          count: changeTypecount(items.request_count, items.Is_Bulk),
          price: changeTypePrice_format({
            price: items.factor_item_price,
            Tax_price: items.Tax_Price,
          }),
          org_title: items.org_title,
          offer: changeTypePrice_format({
            price: parseInt(items.prod_gross_price) - parseInt(items.factor_item_price),
            Tax_price: items.Tax_Price,
          }),
          toll_price: changeTypePrice_format({
            price: (toll_price * items.request_count),
            Tax_price: '',
          })
        }
        Store.dispatch('cargolist',cargo_info)
    }
    }).catch (() => {
        // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

export function factorviweDetail (factorID: string) {
    Store.dispatch('factor_address', {})
    Store.dispatch('factor_const', {})
    axios.post('/api/Store/Factors/view', {
      "ref_id": factorID
    },
    {
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
        if (Response.data.Result.error) {
					Store.dispatch("alertSuccess",{
						show: true,
						type: 'error',
						typeMessage:'network',
						color:"error"
					})
					setTimeout(() => {
						Store.dispatch("alertSuccess",{
							show: false,
							type: 'success',
							typeMessage:'network',
							color:"success"
						})
					}, 3000);
            return
        }
        const factorAddress = Response.data.Result.address
        const factorCost = Response.data.Result.basket
        let offer = 0
        if (factorCost.shipment_profit != 0 && factorCost.shipment_profit) {
            offer = factorCost.shipment_profit
        }
        let settlements = [] as any
        for (const tlement of Response.data.Result.settlements) {
            const set = {
              description: tlement.description,
              amount: changeTypePrice_format({
                  price: tlement.amount,
                  Tax_price: ''
              })
            }
            settlements.push(set)
        }
        const factorAddress_info = {
            receiver: factorAddress.receiver,
            address: factorAddress.province + ' / ' + factorAddress.city + ' / ' + factorAddress.address,
            mobail: factorAddress.mobile,
            phone: factorAddress.phone,
            sender: factorAddress.receiver,
            delivery: factorCost.delivery_date
        }
        const factorCost_info = { 
            post_price: changeTypePrice_format({
                price: factorCost.post_price,
                Tax_price: '0',
            }),
            offer: changeTypePrice_format({
                price: offer,
                Tax_price: '0',
            }),
            cost: changeTypePrice_format({
                price: factorCost.total_format,
                Tax_price: factorCost.tax,
            }),
            costToPay: changeTypePrice_format({
                price: (parseInt(factorCost.total_format) + parseInt(factorCost.post_price)),
                Tax_price: factorCost.tax,
            }),
            settlements: settlements
        }
        Store.dispatch('factor_address', factorAddress_info)
        Store.dispatch('factor_const', factorCost_info)
    }).catch (() => {
        // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}