import router from "@/router"
import axios from "axios"
import Store from '@/store/index'
let config = localStorage.getItem('config')
let _config = {} as any
if (config) {
  _config = JSON.parse(config)
}
export function login(infoLogin: any) {
    Store.dispatch('submit', true)
    Store.dispatch('alertError', {show: false})
    axios.post('/oauth2/token',
    { grant_type: 'password', username: infoLogin.username, password: infoLogin.password, client_id: _config.client_id, client_secret: _config.client_secret }).
    then(Response => {
    if (Response.data.Result.access_token) {
      localStorage.setItem('access_token',Response.data.Result.access_token)
      if (infoLogin.remmber) {
        localStorage.setItem('refresh_token',Response.data.Result.refresh_token)
      }
      Store.dispatch('submit', false)
      router.push('/')
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      Store.dispatch('submit', false)
      if (Response.data.status == 5) {
        Store.dispatch("alertSuccess",{
          show: true,
          type: 'error',
          typeMessage:'not_confirm',
          color:"error"
        })
        setTimeout(() => {
          Store.dispatch("alertSuccess",{
            show: false,
            type: 'success',
            typeMessage:'not_confirm',
            color:"success"
          })
        }, 3000);
        setTimeout(() => {
          localStorage.setItem('confirm_login', infoLogin.username)
          router.push('/authentication')
        }, 2000);
      } else if (Response.data.Result.error == 'invalid_grant') {
        Store.dispatch("alertSuccess",{
          show: true,
          type: 'error',
          typeMessage:'error_pass_user',
          color:"error"
        })
        setTimeout(() => {
          Store.dispatch("alertSuccess",{
            show: false,
            type: 'success',
            typeMessage:'error_pass_user',
            color:"success"
          })
        }, 3000);
      }
    }
    }).catch((error) => {
      // Store.dispatch('submit', false)
      // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

export function logOut() {
  localStorage.setItem('access_token', '')
  localStorage.setItem('refresh_token', '')
  router.push('/login')
  setTimeout(() => {
    location.reload()
  }, 1000);
}

export function forget_password(mobile: any) {
  Store.dispatch('submit', true)
  axios.post('/api/customers/forget', {
    mobile: mobile
  })
  .then(Response => {
    if (Response.data.Result.result == 1) {
      Store.dispatch('submit', false)
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'success',
        typeMessage:'send_code',
        color:"success"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'send_code',
          color:"success"
        })
        router.push('/login')
      }, 3000);
    }
    if (Response.data.Result.result == -2) {
      Store.dispatch('submit', false)
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'no_user',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'send_code',
          color:"success"
        })
        router.push('/register')
      }, 3000);
    }
  }).catch((error) => {
    // Store.dispatch('submit', false)
    // Store.dispatch('alertError', {show: true, type: 'network'})
  })
}

export function register (info_user: any) {
  Store.dispatch('submit', true)
  axios.post('/api/Social/Register/register',info_user)
  .then(Response => {
  if (!Response.data.Result.error) {
    Store.dispatch('submit', false)
    if (Response.data.Result.Result == 6) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'registerd',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'registerd',
          color:"success"
        })
      }, 3000);
      if (Response.data.Result.status == 0) {
        setTimeout(() => {
          localStorage.setItem('confirm_login', info_user.mobile)
          router.push('/authentication')
        }, 2000)
      } else {
        setTimeout(() => {
          router.push('/login')
        }, 2000)
      }
    } else if (Response.data.Result.Result == 1) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'success',
        typeMessage:'registerd',
        color:"success"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'registerd',
          color:"success"
        })
      }, 3000);
      setTimeout(() => {
        localStorage.setItem('confirm_login', info_user.mobile)
        localStorage.setItem('registerd', 'true')
        router.push('/authentication')
      }, 2000);
    }
  } else {
    Store.dispatch('submit', false)
    Store.dispatch("alertSuccess",{
      show: true,
      type: 'error',
      typeMessage:'network',
      color:"error"
    })
    setTimeout(() => {
      Store.dispatch("alertSuccess",{
        show: false,
        type: 'error',
        typeMessage:'network',
        color:"error"
      })
    }, 3000);
  }
  }).catch((error) => {
    // Store.dispatch('submit', false)
    // Store.dispatch('alertError', {show: true, type: 'network'})
  })
}

export function confirm(confirm_code: string) {
  const login_code = localStorage.getItem('confirm_login')
  axios.post('/api/Social/Customers/confirm',{
    "confirm_string" : confirm_code,
    "login_name" : login_code
  }).then(Response => {
    if (Response.data.Result.Result == 1) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'success',
        typeMessage:'confirm',
        color:"success"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'confirm',
          color:"success"
        })
      }, 3000);
      localStorage.removeItem('confirm_login')
      setTimeout(() => {
        router.push('/login')
      }, 2000);
    } else if (Response.data.Result.Result == -1) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'old_confirm',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'error',
          typeMessage:'old_confirm',
          color:"error"
        })
      }, 3000);
    }  else if (Response.data.Result.Result == -2) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'bad_confirm',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'error',
          typeMessage:'old_confirm',
          color:"error"
        })
      }, 3000);
    }
  })
}

export function resend_code() {
  const mobile = localStorage.getItem('confirm_login')
  axios.post('/api/Customers/resendActivationCode', {
    cellphone: mobile
  }).then(Response => {
    if (Response.data.Result.Result == 1) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'success',
        typeMessage:'send_again',
        color:"success"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'send_again',
          color:"success"
        })
      }, 3000);
    }
  })
}