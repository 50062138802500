<template>
  <div>
    <v-card class="mt-2 card pa-3 w-100">
      <v-card-subtitle class="titleFont--text font-weight-medium px-0">
        <div class="d-flex align-center">
          <span class="font" v-text="receiver.title"></span>
          <v-spacer></v-spacer>
          <v-icon @click.native="removeAddress()" size="20" class="error--text mx-2 cursor-pointer">mdi-trash-can-outline</v-icon>
        </div>
      </v-card-subtitle>
        <v-row>
          <v-col class="py-2">
            <v-icon>mdi-account</v-icon>
            <span class="medium-font mx-1" v-text="receiver.receiver"></span>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-2">
            <v-icon>mdi-sign-real-estate</v-icon>
            <span class="medium-font mx-1">
            <span v-text="receiver.province"></span>/<span v-text="receiver.city"></span>/ <span v-text="receiver.address"></span></span>
          </v-col>
        </v-row>

        <v-row v-if="receiver.postal_code">
          <v-col class="py-2">
            <v-icon>mdi-email</v-icon>
            <span class="medium-font mx-1 numbers-font" v-text="receiver.postal_code"></span>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-2">
            <v-icon>mdi-cellphone-iphone</v-icon>
            <span class="medium-font mx-1 numbers-font" v-text="receiver.mobile"></span>
          </v-col>
        </v-row>

        <v-row v-if="receiver.phone">
          <v-col class="py-2">
            <v-icon>mdi-phone</v-icon>
            <span class="medium-font mx-1 numbers-font" v-text="receiver.phone"></span>
          </v-col>
        </v-row>
    </v-card>
  </div>
</template>
<script>
import { deletedAddress } from '@/views/adminPanel/models/address'
export default {
  props: ['receiver'],
  methods: {
    removeAddress () {
      deletedAddress(this.$props.receiver.id)
    }
  }
}
</script>
